.loading {
    margin: 20px;
    text-align: center;
}

.loading span {
    color: var(--secondary-color);
}

.favorite-modal .modal-body{
    background: rgba(255, 255, 205, 0.35);
}
