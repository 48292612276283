.receipt-item {
    padding: 8px 8px 6px 8px;
}

.receipt-item li {
    list-style: none;
}

.receipt-list .list-group-item:nth-child(odd) {
    background-color: white;
}

.receipt-list {
    border-radius: 20px;
    background: var(--selection-background-color);
    padding: 0;
}

.monthly-receipt-list .month {
    padding-left: 16px;
    font-size: 18px;
    background: var(--background-color);
    box-shadow: none;
}

.receipt-list > .list-group-item:first-child {
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
}

.receipt-list > .list-group-item:last-child {
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
}

.receipt-list .list-group-item {
    background: none;
    margin: 0;
    padding: 0;
}

/*.receipt-list > :first-child > a > * {*/
/*    border-top-right-radius: 20px;*/
/*    border-top-left-radius: 20px;*/
/*}*/

/*.receipt-list > :last-child > a > * {*/
/*    border-bottom-right-radius: 20px;*/
/*    border-bottom-left-radius: 20px;*/
/*}*/

.receipt-list .list-group-item > a {
    color: var(--text-color);
}

.receipt-list .list-group-item > a:hover {
    background: white;
}

.receipt-list .list-group-item > a > div {
    padding: 8px 4px 6px 8px
}

.receipt-list .list-group-item > a:focus {
    text-decoration: none;
}

.receipt-list .list-group-item > a.active > * {
    background: white;
}
.receipt-list > div:hover > * {
    color: var(--text-color);
    text-decoration: none;
}


.list-group-item > a > .receipt-item {
    background: var(--selection-background-color);
}

.receipt-item .drugs {
    margin-top: 4px;
    margin-bottom: 2px;
}

.receipt-item .drugs > li:not(:first-child) {
    border-left:1px solid darkslategrey;
}
.receipt-item .drugs > li {
    float: left;
    padding-left: 2px;
    padding-right: 2px;
}

.receipt-item .drugs li {
    font-size: smaller;
}
