
.patient-search-container {
    
    padding: 20px;
    border-radius: 20px;
    min-height: unset;
}

.patient-search-container h3 {
    color: white;
    margin-top: 0;
}
