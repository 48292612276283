.billing-form  .input-group-addon {
    min-width: 50px;
}

/*.billing-form .first-right-child {*/
/*    border-top-right-radius: 20px;*/
/*}*/

/*.billing-form .first-left-child {*/
/*    border-top-left-radius: 20px;*/
/*}*/
/*.billing-form .last-right-child {*/
/*    border-bottom-right-radius: 20px;*/
/*}*/
/*.billing-form .last-left-child {*/
/*    border-bottom-left-radius: 20px;*/
/*}*/

/*.billing-form .input-group > input {*/
/*    background: none;*/
/*    border-top: none;*/
/*    border-left: none;*/
/*    border-right: none;*/
/*    box-shadow: none;*/
/*    border-bottom: 1px solid grey;*/
/*}*/

/*.billing-form .input-group {*/
/*    margin-left: 8px;*/
/*}*/


/*.billing-form .input-group-addon {*/
/*    background: none;*/
/*    border-top: none;*/
/*    border-left: none;*/
/*    border-right: none;*/
/*    box-shadow: none;*/
/*    border-bottom: 1px solid grey;*/
/*    border-bottom-right-radius: 0;*/
/*    border-bottom-left-radius: 0;*/
/*}*/

/*.billing-address-form .form-horizontal .form-group {*/
/*    margin-left: 0;*/
/*    margin-right: 0;*/
/*}*/

.cards-list {
    margin-bottom: 0;
    box-shadow: none;
}

.card-line {
    padding: 0;
    border: 0;
    box-shadow: none;
    outline: none;
    display: flex;
    flex-direction: row;
    justify-items: flex-start;
    align-items: flex-start;
    align-content: flex-start;
}

.card-line .card-type {
    padding-top: 1px;
    flex: 0 1 auto;
    margin-right: 8px;
}

.card-line .card-number {
    padding-top: 2px;
    line-height: 28px;
    flex: 1 0 auto;
}

.card-line .action-buttons {
    padding-top: 4px;
    flex: 1 1 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-content: center;
    align-items: center;
}

.card-line .action-buttons > * {
    order: 0;
    flex: 0 1 auto;
    align-self: auto;
    margin-left: 4px;
}

.fiscal-form .inner-left-column {
    padding-left: 0;
}

.fiscal-form .inner-right-column {
    padding-right: 0;
}
