.text-align-right {
    text-align: right;
}

.prescriber-cell {
    min-width: 150px;
}

.date-cell {
    min-width: 100px;
}

.medication-list .separator-line > td {
    padding-top: 3px;
    padding-bottom: 3px;
    font-size: 75%;
    font-weight: 900;
}
