.page-header.margin-small {
    margin: 8px 0 4px 0;
    padding-bottom: 3px;
}

.page-header > * > span.pull-right {
    margin-top: -5px;
}

.page-header.no-hr {
    border-bottom: none;
}
