.doctor-container {
    background: whitesmoke;
    padding: 0 6px 6px 6px;
    min-height: 100%;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
}

.doctor-container .id-container {
    border: 1px solid var(--border-color-light);
    margin: 0  auto 6px auto;
    border-radius: 20px;
    width: 100%;
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
    -webkit-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    align-self: auto;
    padding: 20px;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.doctor-container .id-container .photo {
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    align-self: auto;
    height: 120px;
    width: 120px;

    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 6px;
    text-align: center;
}

.doctor-container .id-container .photo .profile-photo {
    width: 100px;
    height: 100px;
    border-radius: 20px;
    overflow: hidden;
    border: 4px solid var(--primary-color);
    box-shadow: 0 0 0 4px var(--primary-faded-color);
    transition: all 0.3s ease-in-out;
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
    -webkit-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    align-self: auto;
}

.doctor-container .id-container .information {
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    align-self: auto;
    padding-right: 6px;
}

.doctor-container .id-container .information .name {
    font-size: x-large;
}

.doctor-container .id-container .information .number {
    cursor: default;
    font-size: unset;
    text-align: center;
}

.doctor-container .id-container .information .secondary-info {
    margin-top: 10px;
    margin-bottom: 10px;
}

.doctor-container .id-container .information .secondary-info > * {
    margin-top: 10px;
    margin-bottom: 10px;
}

.doctor-container .id-container .information h3 {
    margin-top: 0;
}

.doctor-container .id-container .information > div, h3 {
    margin-left: 1px;
}

.doctor-container .actions-container {
    border: 1px solid var(--border-color-light);
    border-radius: 20px;
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
    -webkit-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    align-self: auto;
}


.doctor-container .actions-container .buttons {
    margin: 30px auto;
    width: 100%;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: space-around;
    -ms-flex-pack: justify;
    justify-content: space-around;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.doctor-container .actions-container .buttons .btn {
    padding-left: 24px;
    padding-right: 24px;
}
