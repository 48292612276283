.register{
    background: -webkit-linear-gradient(left, var(--secondary-color), var(--primary-color));
    padding: 3%;
    border-radius: 20px;
}
.register-left{
    text-align: center;
    color: #fff;
    margin-top: 4%;
}

.register-left .container-left {
    margin-left: -20%;
}
.register-left h1 {
    margin-top: 0;
}
.register-left .btn{
    margin-top: 30%;
    margin-bottom: 3%;
}
.register-right{
    background: #f8f9fa;
    border-radius: 20px;
}
.register-left img{
    margin-top: 15%;
    margin-bottom: 5%;
    width: 50%;
    -webkit-animation: rotating 10s linear infinite;
    animation: rotating 10s linear infinite;
}

.register-right .radio-form-group .form-control{
    background: #f8f9fa;
}

@-webkit-keyframes rotating /* Safari and Chrome */ {
    from {
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes rotating {
    from {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.register .register-form{
    padding: 0 20px 20px;
}
.register-heading{
    text-align: center;
    color: #495057;
}

@media screen and (max-width: 769px) {
    .register {
        margin-top: -50px;
        border-radius: 0;
    }
    .register-left .btn{
        display: none;
    }

    .register-left .container-left {
        margin-left: unset;
    }
    .register-left img {
        margin-bottom: 5px;
        margin-top: 0;
    }
    .register-heading {
        padding-top: 10px;
    }
}

