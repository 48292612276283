.static-medicine {
    margin-bottom: 16px;
    padding: 4px;
    border-radius: 20px;
}

.static-medicine.row {
    margin-left: inherit;
    margin-right: inherit;
    padding-left: 16px;
    padding-right: 16px;
    line-height: 36px;
}

.static-medication-info-line > .main {
    display: inline;
}

.static-medication-info-line span:not(:first-child){
    border-left:1px solid darkslategrey;
    margin-left: 2px;
    padding: 0 4px 0 4px;
}

.static-medicine > .dosage {
    color: gray;
}

.static-medicine .dosage-text {
    color: var(--text-color);
    font-weight: bold;
}

.has-warning .form-control {
    border-color: #ff3333 !important;
}

.has-warning .form-control-feedback {
    color: #ff3333;
}

.has-warning .form-control:focus {
    border-color: #ff3333;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 6px #ff3333;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 6px #ff3333;
}


@media screen and (max-width: 786px) {
    .static-medicine > .renewable {
        text-align: left;
    }

    .static-medication-info-line > .secondary {
        display: block;
    }
}

.static-medicine > .renewable {
    text-align: right;
}

.static-medicine > div > .quantity {
    text-align: right;
}


.static-medicine .badge {
    background-color: #77ccae !important;
    font-size: 13px;
}


.bottom-line {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.bottom-line > *:nth-child(1) {
    flex: 1 0 auto;
    padding-right: 8px;
}

.bottom-line > *:nth-child(2) {
    flex: 0 1 auto;
}
