.btn .caret {
    margin-left: 4px;
}
.no-margin-form-group {
    margin-bottom: 0;
}

.medicine-search-input {
    min-width: 250px;
}

.popped-list {
    margin-top: 0;
    position: absolute;
    z-index: 9999;
    width: 90%;
    overflow-y: scroll;
    max-height: 250px;
    box-shadow: 0 3px 5px 0 rgba(0,0,0,0.25);
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.grouped-item .popped-list {
    margin-top: 34px;
}

.admin-ways .popped-list {
    margin-top: -14px;
    background: white;
    width: calc(100% - 10px);
    margin-left: 5px;
}
