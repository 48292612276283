.home-container {
    padding-top: 36px;
}

.centered-main-navigation {
    padding-top: 50px;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    height: calc(100%);
    min-height: 450px;
    /* background: url('./medBG2.png'); */
    background-size: cover;
}

.centered-main-navigation .jumbotron {
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 10px;
    background: none;
}

.doctor-image {
    height: 110px;
    width: 110px;
    border: 6px solid var(--secondary-color);
    border-radius: 16px;
    background: white;
    margin: 10px auto;
    color: var(--secondary-color);
    overflow: hidden;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.doctor-image > span {
    margin-bottom: -8px;
    color: var(--secondary-color)
}

.greetings {
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
}

.main-content {
    margin-bottom: 50px;
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
    -webkit-flex: 1 0 auto;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.main-content .flex-inner-item {
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
    margin: auto 16px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.main-navigation-button-container {
    margin: 10px auto;
    height: 160px;
    width: 160px;
}

.main-navigation-button-container a:focus, a:hover{
    text-decoration: none;
    outline: none;
}

.main-navigation-button {
    width: 100%;
    height: 100%;
    background-image: -webkit-linear-gradient(top, #03c1af 0, #028578 100%);
    background-image: -o-linear-gradient(top, #03c1af 0, #028578 100%);
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, #03c1af), to(#028578));
    background-image: linear-gradient(to bottom, #03c1af 0, #028578 100%);
    border-radius: 42%;
    color: white;
    padding: 32px;
    font-size: 20px;
    border: none;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: space-around;
    -ms-flex-pack: justify;
    justify-content: space-around;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.main-navigation-button > span {
    color: white;
}

.responsive-icon {
    font-size: 6rem;
}

.breadcrumb {
    margin: 0;
}

.breadcrumbs {
    position: fixed;
    z-index: 100;
    width: 100%;
}

.breadcrumb .right-field {
    float: right;
}


.navbar-nav .double-line-navigation > div > li > a {
    color: var(--text-color);
}

.navbar-nav .double-line-navigation > div > li > a:focus {
    text-decoration: none;
}

.navbar-nav .double-line-navigation > div > li >  .active-nav {
    font-size: unset;
}

.double-line-navigation {
    margin-top: 6px;
    margin-bottom: 6px;
}
.double-line-navigation .secondary-info {
    font-size: smaller;
    text-align: center;
}






@media screen and (max-width: 992px) {
    .navbar-form > .input-group > .form-control {
        width: 110px;
    }
}

@media screen and (max-width: 768px) {

    .responsive-icon {
        font-size: 4rem;
    }

    .greetings > h2 {
        font-size: 20px;
    }

    .doctor-image {
        height: 100px;
        width: 100px;
    }

    .doctor-image > span {
        font-size: 7rem;
    }

    .flex-item:nth-child(2) {
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        flex-wrap: wrap;
    }
    .main-navigation-button-container {
        width: 120px;
        height: 120px;
    }
    .main-navigation-button {
        padding: 16px;
        width: 120px;
        height: 120px;
    }

    .main-navigation-button > div {
        font-size: 14px;
    }
}

@media (max-width: 400px) {
    .home-container {
        padding-top: 0;
    }
    .home-container > div {
        padding-top: 50px;
    }

    .breadcrumb {
        padding-left: 50px;
    }
}
