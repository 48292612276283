.signer > * {
    font-size: larger;
}

.medicine-line {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    align-content: center;
}

.medicine-line-item:nth-child(1) {
    order: 0;
    flex: 1 1 auto;
    align-self: auto;
    margin-right: 4px;
}

.medicine-line-item:nth-child(2) {
    order: 0;
    flex: 0 0 auto;
    align-self: stretch;
    text-align: right;
}

.medicines-container {
    min-height: 110px;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: center;
    align-content: center;
}

.medicine-line {
    padding: 4px 6px;
}

.medicines-list .medicine-line {
    border-radius: 20px;
}

.margin-top {
    margin-top: 8px
}

.no-side-padding {
    padding-left: 0;
    padding-right: 0;
}
.receipt-separator {
    margin-top: 4px;
    margin-bottom: 4px;
}

.medicines-list {
    margin-bottom: 0;
}

.no-margin-hr {
    margin-top: 0;
    margin-bottom: 0;
}

.medicines-container {
    min-height: 112px;
}

.medicines-container .list-group-item:first-child {
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
}

.medicines-container .list-group-item:last-child {
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
}

.medicines-container .list-group-item {
    padding-left: 16px;
    padding-right: 16px;
    border-radius: 0;
}

.signer > div > div:last-child {
    padding-top: 5px;
}


.medicines-list {
    box-shadow: none;
}

.confirmation-message {
    padding-top: 4px;
}
