.prescribe-container {
    min-height: 320px;
}

.prescribe-container .modal-body {
    background: var(--background-color);
}
.prescribe-container .basket-float-btn {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
    padding-right: 0;
}

.prescribe-modal {
    height: 100%;
}

.prescribe-modal .modal-content {
    height: 100%;
    background: #e6f1ee;
}

.prescribe-modal .modal-body {
    max-height: calc(100% - 56px);
}

.prescribe-modal-header {
    border-bottom-color: var(--border-color);
}

.row-fluid {
    margin-right: -15px;
    margin-left: -15px;
}

.row-fluid div {
    -webkit-transition: width 0.1s ease, height 0.1s ease, margin 0.1s ease, padding 0.1s ease, opacity 0.1s ease;
    -moz-transition: width 0.1s ease, height 0.1s ease, margin 0.1s ease, padding 0.1s ease, opacity 0.1s ease;
    -o-transition: width 0.1s ease, height 0.1s ease, margin 0.1s ease, padding 0.1s ease, opacity 0.1s ease;
    transition: width 0.1s ease, height 0.1s ease, margin 0.1s ease, padding 0.1s ease, opacity 0.1s ease;
}

.favorites-panel .panel-body {
    padding: 0;
}

.favorites-panel .accordion > *:first-child {
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
}

.favorites-panel .accordion__body {
    padding: 0
}

.favorites-panel .panel-body .accordion {
    background: transparent;
    border: none;
    border-radius: 0;
}

.prescribe-container .loading {
    margin: 20px;
    text-align: center;
}

.prescribe-container .loading span {
    color: var(--secondary-color);
    /*animation: rotating 2s linear infinite;*/
}

.yellow-icon {
    color: #ffd500;
}

.buttons-container > *{
    margin-left: 2px;
    margin-right: 2px;
}

@media (max-width: 768px) {
    .prescribe-container > div{
        padding-right: 4px;
    }
}


@-webkit-keyframes rotating /* Safari and Chrome */ {
    from {
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes rotating {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
