.guide-modal .modal-body{
    min-height: 300px;
    background: var(--background-color);
}

.dropdown.align-left .dropdown-menu{
    left: 0;
    right: unset;
}

.dropdown.align-right .dropdown-menu{
    right: 0;
    left: unset;
}

.dropdown .dropdown-menu>li>button{
    display: block;
    clear: both;
    font-weight: 400;
    line-height: 1.42857143;
    color: #333;
    white-space: nowrap;
    border: none;
    background: none;
}

.button-inline-form {
    margin-top: 24px;
}

.guide-entry-line .periods {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
}

.guide-entry-line .periods .title {
    line-height: 38px;
    margin-right: 4px;
}

.guide-entry-line .periods .period-item {
    background-color: var(--badge-color);
    color: white;
    font-weight: bold;
    border-radius: 20px;
    padding: 0 10px;
    line-height: 30px;
    margin: 4px 4px 4px 0;
}

.guide-entry-line .units {
    background: var(--secondary-color);
    color: white;
    border-radius: 20px;
    padding: 0 6px;
    line-height: 30px;
    margin: 4px 8px 4px 0;
}

.guides-list {
    border-radius: 20px;
}

.guides-list .guide-item {
    border-radius: 20px;
    margin-bottom: 8px;
}


.receipt-choice-list > a {
    color: unset;
}

.receipt-choice-list {
    box-shadow: none;
    border-radius: 20px;
}

.receipt-choice-list .receipt-item {
    border-radius: 20px;
    margin-bottom: 8px;
}

.line {
    font-size: x-large;
    line-height: 38px;
    margin-left: 8px;
    margin-right: 8px;
}


.inline-form-group-checkbox {
    margin-top: 22px;
}
.input-group-addon.btn-left {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}

.input-group-addon.btn-right {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
}


.guide-panel .panel-body {
    padding: 10px 0 10px 0;
}

.guide-panel .panel-heading .row{
    line-height: 30px;
}

.guide-panel .form-horizontal .form-group {
    margin-left: 0;
    margin-right: 0;
}

.frequence-select {
    max-width: 260px;
}

.frequence-select select {
    min-width: 30px;
}

.frequence-select .input-group-addon {
    padding-left: 4px;
    padding-right: 4px;
}

.frequence-select .input-group-addon span {
    max-width: 165px;
    text-overflow: ellipsis;
    display: block;
    white-space: nowrap;
    overflow: hidden;
}

.inline-form-group-checkbox {
    margin-top: 20px;
}

.input-select-group {
    display: inline;
}

.input-select-group .form-control:nth-child(1) {
    width: 35%;
}

.input-select-group .form-control:nth-child(2) {
    width: 65%;
}

.checkbox-inline+.checkbox-inline {
    margin-left: 0;
}

@media screen and (max-width: 768px)  {
    .frequence-select .input-group-addon {
        max-width: 155px;
    }
    .frequence-addon {
        max-width: 200px;
    }
}


.receipt-guides-panel-container .panel-body {
    padding: 0
}

.receipt-guides-panel-container .receipt-selector {
    width: 100%;
    padding: 0;
}

.receipt-guides-panel-container .receipt-selector .receipt-list {
    margin-top: 0;
    margin-bottom: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.receipt-guides-panel-container .receipt-selector .receipt-list > .list-group-item:last-child {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}
.receipt-guides-panel-container .receipt-selector .receipt-list > .list-group-item {
    border-radius: 0;
}


.guide-title > *:nth-child(1) {

}

.guide-title > *:nth-child(2) {
    padding-left: 16px;

}


.medicine-item:nth-child(odd) {
    background: rgba(198, 222, 215, 0.5);
}

.medicine-item:nth-child(even) {
    background: var(--selection-background-color);
}

.medicine-item {
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    margin-bottom: 8px;
    padding: 12px;
}

.medicine-item .name {
    font-size: 110%;
}
