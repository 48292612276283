.modal-open {
    overflow: hidden;
}

.ReactModal__Overlay {
    z-index: 1000;
}

.ReactModal__Overlay--after-open {
    background-color: rgba(0, 0, 0, 0.5);
}

.modal-dialog {
    -webkit-transform: translateZ(0);
    overflow: hidden;
    -webkit-box-shadow: 0 3px 9px rgba(0,0,0,.5);
    -moz-box-shadow: 0 0 5px 2px #fff;
    box-shadow: 0 3px 9px rgba(0,0,0,.5);
    background: #e6f1ee;
    -moz-border-radius: 20px;
    -webkit-border-radius: 20px;
    border-radius: 20px;
    background-clip: padding-box;
    -webkit-background-clip: padding-box;
    max-height:92%;
}

.modal .modal-content {
    box-shadow: none;
    border: none;
}

.modal .modal-header .close {
    padding-top: 5px;
    color: rgba(0,0,0,1);
}

.modal .modal-header {
    background-color: var(--border-color-light);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

.modal .modal-body > .modal-footer {
    border: 1px solid green;
}

.modal .modal-footer {
    background-color: var(--border-color-light);
}

.full-width {
    width: 95%;
}

.modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto;
}

.modal-dialog:focus {
    outline: none;
}

.modal-body {
    max-height: calc(100vh - 200px);
    overflow-y: auto;
}


@media (min-width: 900px) {
    .modal-lg {
        width: 90%;
    }
}

@media (min-width: 1200px) {
    .modal-lg {
        width: 75%;
    }
}


@media screen and (min-width: 768px) {
    .modal-dialog {
        -webkit-box-shadow: 0 5px 15px rgba(0,0,0,.5);
        box-shadow: 0 5px 15px rgba(0,0,0,.5);
    }
}

@media screen and (max-width: 768px) {
    .full-width {
        width: 100%;
        margin: 0;
        max-height: 100%;
        height: 100%;
    }
    .modal-dialog {
        border-radius: 0;
    }

    .modal-body {
        position: relative;
        padding-right: 0;
        padding-left: 0;
        max-height: calc(100vh - 55px);
        overflow-y: auto;
    }

    .modal-content {
        height: 100%;
        border-radius: 0;
        border: none;
    }
}
