.patient-insertion .unaligned-fields {
    padding-right: 36px;
}


@media (max-width: 768px) {
    .patient-insertion {
        padding-left: 0;
        padding-right: 0;
    }

    .patient-insertion > * {
        padding-left: 0;
        padding-right: 0;
    }
}
