.grouped-inputs {
    width: 100%;
    background: white;
    border: 1px solid #ccc;
    box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
    padding: 6px 12px;
    border-radius: 20px;
    float: left;
    margin-bottom: 0;
}

.grouped-input > input {
    width: auto !important;
    border: none;
    box-shadow: none;
    padding-left: 1px;
}

.grouped-input > select {
    width: auto !important;
    border: none;
    box-shadow: none;
    padding-left: 1px;
}

.grouped-input > input:focus {
    outline: none;
    box-shadow: none;
}

.grouped-input > select:focus {
    outline: none;
    box-shadow: none;
}

.grouped-input > .input-group-addon {
    padding: 0;
    border: none;
    background: none;
}

.grouped-input > .input-group-addon > span {
    background: #ccc;
    border: 1px solid #ccc;
    border-radius: 2px;
    font-size: smaller;
    padding: 2px
}
