
.contacts-container {
    height: calc(100%);
    padding: 0;
    display: flex;
    flex-direction: row;
}

.contacts-container a:focus {
    text-decoration: none;
}
.select-patient {
    height: 100%;
    margin: 0;
    background: whitesmoke;
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    justify-content: center;
}

.contacts-container .side-container {
    padding-left: 0;
    padding-right: 0;
    padding-top: 10px;
    margin-left: 0;
    margin-right: 0;
    border-right: 1px solid var(--border-color);
    height: 100%;
    overflow-y: hidden;
}

.search-container {
    position: absolute;
}

.contacts-container .side-container .selector-content > .nav {
    padding-top: 50px;
    margin-left: 10px;
}

.contacts-container .side-container .nav > .pull-right {
    padding-top: 8px;
    margin-right: 14px;
    height: 42px;
}

.contacts-container .side-container .nav .pull-right > .btn {
    padding: 3px 8px;
}

.contacts-container .side-container .selector-content {
    height: 100%;
    width: 100%;
}

.contacts-container .side-container .tab-content {
    height: calc(100% - 100px);
    overflow-y: scroll;
    overflow-x: hidden;
}

.contacts-container .side-container .tab-content > div {
    margin-left: 10px;
    margin-right: -15px;
    padding-right: 15px;
}

.contact-card {
    background: var(--selection-background-color);
    border: 1px solid var(--border-color);
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: flex-start;
    -webkit-align-content: space-between;
    -ms-flex-line-pack: justify;
    align-content: space-between;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 6px;
    border-radius: 16px;
    padding: 4px 8px;
}

a.active > .contact-card {
    background: white;
    box-shadow: 2px 2px 5px 0 var(--box-shadow-color);
}

.contact-card .content {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-content: space-between;
    -ms-flex-line-pack: justify;
    align-content: space-between;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    color: var(--text-color);
}

.contact-card:hover {
    box-shadow: 2px 2px 5px 0 var(--box-shadow-color);
}

.contact-card .content .photo-container {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 6px;
    text-align: center;
}

.contact-card .content .photo-container .photo {
    border-radius: 50%;
    overflow: hidden;
    border: 1px solid var(--primary-faded-color);
    box-shadow: 0 0 0 1px var(--primary-faded-color);
    transition: all 0.3s ease-in-out;
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
    -webkit-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    align-self: auto;
    width: 35px;
    height: 35px;
    margin-right: 6px;
}

.contact-card .content .photo-container .photo.professional {
    border-radius: 5px;
}

.contact-card.active .content .photo-container .photo {
    box-shadow: 0 0 0 4px var(--primary-faded-color);
    transition: all 0.3s ease-in-out;
}

.contact-card .content .information {
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
    -webkit-flex: 1 2 auto;
    -ms-flex: 1 2 auto;
    flex: 1 2 auto;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    align-self: center;
}

.contact-card .content .information .gender.fa-venus {
    margin-right: 4px;
    color: hotpink;
}

.contact-card .content .information .gender.fa-mars {
    margin-right: 4px;
    color: dodgerblue;
}

.contact-card .content .information .name {
    font-size: larger;
}



.contact-card .buttons {
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
    -webkit-flex: 1 2 auto;
    -ms-flex: 1 2 auto;
    flex: 1 2 auto;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    align-self: auto;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;

}

.contact-card .buttons > * {
    background: none;
    border: none;
    margin-left: 16px;
    margin-right: 16px;
}

.contact-card .buttons > * > * {
    background: none;
    border: none;
    color: var(--text-color);
}


@media screen and (max-width: 768px) {
    .contact-card {
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-align-content: center;
        -ms-flex-line-pack: center;
        align-content: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
    }

    .contact-card .buttons {
        height: 64px;
    }
}
