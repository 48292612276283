
/*Colors definition */
:root {
    --background-color: #f4f2ec;
    --primary-color: #74b09e;
    --primary-faded-color: #c6ded7;
    --secondary-color: #325291;

    --side-select-color: #74a4b0;

    --text-color: #333333;
    --text-white-color: #fbfbfb;
    --text-selected-color: #182628;

    --border-color: #b7b7b7;
    --border-color-light: #dddddd;
    --box-shadow-color: rgba(0,0,0,0.1);

    --attention-color: #E27D60;
    --selection-background-color: #f3f3f3;

    --badge-color: #7c9cca;

    --light-blue: #74c0d0;

    --light-blue-button-background: linear-gradient(to bottom,#b4ecff 0, #9be4ff 100%);
    --light-blue-button-color: #9be4ff;

    --dark-blue-button-background: linear-gradient(to bottom, #33ccff 0, #00bfff 100%);
    --dark-blue-button-color: #00bfff;
}
/*End of Colors definition */



html {
    height: 100%;
}
body {
    background-color: #f4f2ec;
    background-color: var(--background-color);
    height: 100%;
    margin: 0;
    padding: 50px 0 0;
    font-family: 'Open Sans', sans-serif !important;
}


#root {
    height: calc(100%);
}

ul {
    -moz-padding-start: 0;
    -webkit-padding-start: 0;
}

.border {
    border: 1px solid red;
}

.testing * {
    border: 1px solid;
}

.icon-margin-right {
    margin-right: 8px;
}

.no-margin-hr {
    margin-top: 0;
    margin-bottom: 0;
}

.btn {
    border-radius: 20px;
    padding-left: 14px;
    padding-right: 14px;
}

.btn-md {
    padding: 12px;
    font-size: larger;
}

button:focus {
    outline: none;
}

.full-height {
    height: calc(100%);
}

.button-fa {
    line-height: 1.42857143 !important;
}

*:focus {
    outline: none !important;
    color: #333333 !important;
    color: var(--text-color) !important;
}

.no-side-padding-for-container {
    padding-left: 0;
    padding-right: 0;
}

.no-side-padding {
    padding-left: 0;
    padding-right: 0;
}

.small-side-padding {
    padding-left: 2px;
    padding-right: 2px;
}

.btn-primary:focus {
    color: #fbfbfb !important;
    color: var(--text-white-color) !important;
}

.btn-success:focus {
    color: #fbfbfb !important;
    color: var(--text-white-color) !important;
}


.small-margin {
    margin: 2px
}


.bordered {
    border: 1px solid red;
}

.margin-bottom-form-input {
    margin-bottom: 15px;
}

.faded {
    opacity: 0.5;
}

.border-all > * {
    border: 1px solid red;
}

.loading-window {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.form-control.input-sm {
    line-height: unset;
}

.loading-window > span {
    color: #325291;
    color: var(--secondary-color);
}

.loading {
    margin: 20px;
    text-align: center;
}

.loading span {
    color: #325291;
    color: var(--secondary-color);
}


.hover-effect:hover {
    box-shadow: 2px 2px 5px 0 rgba(0,0,0,0.1);
    box-shadow: 2px 2px 5px 0 var(--box-shadow-color);
}

@charset "UTF-8";
/**
 * Mixin to create a keyframes for a notification
 * @param $direction (left|top|right|bottom) Translation direction
 * @param $margin-bottom Initial `margin-bottom` value
 */
/*
 * Mixin to create an animation for a notification
 * @param $direction (left|top|right|bottom) Translation direction
 * @param $margin-bottom Initial `margin-bottom` value
 */
@-webkit-keyframes top20px-enter {
    0% {
        top: -360px;
        opacity: 0;
    }
    100% {
        top: 0;
        opacity: 1;
    }
}
@keyframes top20px-enter {
    0% {
        top: -360px;
        opacity: 0;
    }
    100% {
        top: 0;
        opacity: 1;
    }
}

@-webkit-keyframes top20px-leave {
    0% {
        -webkit-transform: translateY(0%);
        transform: translateY(0%);
        opacity: 1;
        max-height: 200px;
        margin-bottom: 20px;
    }
    40% {
        margin-bottom: 20px;
        max-height: 200px;
    }
    50% {
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%);
        opacity: 0;
        max-height: 0;
        margin-bottom: 0;
    }
}

@keyframes top20px-leave {
    0% {
        -webkit-transform: translateY(0%);
        transform: translateY(0%);
        opacity: 1;
        max-height: 200px;
        margin-bottom: 20px;
    }
    40% {
        margin-bottom: 20px;
        max-height: 200px;
    }
    50% {
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%);
        opacity: 0;
        max-height: 0;
        margin-bottom: 0;
    }
}

@-webkit-keyframes bottom20px-enter {
    0% {
        bottom: -360px;
        opacity: 0;
    }
    100% {
        bottom: 0;
        opacity: 1;
    }
}

@keyframes bottom20px-enter {
    0% {
        bottom: -360px;
        opacity: 0;
    }
    100% {
        bottom: 0;
        opacity: 1;
    }
}

@-webkit-keyframes bottom20px-leave {
    0% {
        -webkit-transform: translateY(0%);
        transform: translateY(0%);
        opacity: 1;
        max-height: 200px;
        margin-bottom: 20px;
    }
    40% {
        margin-bottom: 20px;
        max-height: 200px;
    }
    50% {
        -webkit-transform: translateY(100%);
        transform: translateY(100%);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(100%);
        transform: translateY(100%);
        opacity: 0;
        max-height: 0;
        margin-bottom: 0;
    }
}

@keyframes bottom20px-leave {
    0% {
        -webkit-transform: translateY(0%);
        transform: translateY(0%);
        opacity: 1;
        max-height: 200px;
        margin-bottom: 20px;
    }
    40% {
        margin-bottom: 20px;
        max-height: 200px;
    }
    50% {
        -webkit-transform: translateY(100%);
        transform: translateY(100%);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(100%);
        transform: translateY(100%);
        opacity: 0;
        max-height: 0;
        margin-bottom: 0;
    }
}

@-webkit-keyframes left20px-enter {
    0% {
        left: -360px;
        opacity: 0;
    }
    100% {
        left: 0;
        opacity: 1;
    }
}

@keyframes left20px-enter {
    0% {
        left: -360px;
        opacity: 0;
    }
    100% {
        left: 0;
        opacity: 1;
    }
}

@-webkit-keyframes left20px-leave {
    0% {
        -webkit-transform: translateX(0%);
        transform: translateX(0%);
        opacity: 1;
        max-height: 200px;
        margin-bottom: 20px;
    }
    40% {
        margin-bottom: 20px;
        max-height: 200px;
    }
    50% {
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
        opacity: 0;
        max-height: 0;
        margin-bottom: 0;
    }
}

@keyframes left20px-leave {
    0% {
        -webkit-transform: translateX(0%);
        transform: translateX(0%);
        opacity: 1;
        max-height: 200px;
        margin-bottom: 20px;
    }
    40% {
        margin-bottom: 20px;
        max-height: 200px;
    }
    50% {
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
        opacity: 0;
        max-height: 0;
        margin-bottom: 0;
    }
}

@-webkit-keyframes right20px-enter {
    0% {
        right: -360px;
        opacity: 0;
    }
    100% {
        right: 0;
        opacity: 1;
    }
}

@keyframes right20px-enter {
    0% {
        right: -360px;
        opacity: 0;
    }
    100% {
        right: 0;
        opacity: 1;
    }
}

@-webkit-keyframes right20px-leave {
    0% {
        -webkit-transform: translateX(0%);
        transform: translateX(0%);
        opacity: 1;
        max-height: 200px;
        margin-bottom: 20px;
    }
    40% {
        margin-bottom: 20px;
        max-height: 200px;
    }
    50% {
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
        opacity: 0;
        max-height: 0;
        margin-bottom: 0;
    }
}

@keyframes right20px-leave {
    0% {
        -webkit-transform: translateX(0%);
        transform: translateX(0%);
        opacity: 1;
        max-height: 200px;
        margin-bottom: 20px;
    }
    40% {
        margin-bottom: 20px;
        max-height: 200px;
    }
    50% {
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
        opacity: 0;
        max-height: 0;
        margin-bottom: 0;
    }
}

@-webkit-keyframes top0-enter {
    0% {
        top: -360px;
        opacity: 0;
    }
    100% {
        top: 0;
        opacity: 1;
    }
}

@keyframes top0-enter {
    0% {
        top: -360px;
        opacity: 0;
    }
    100% {
        top: 0;
        opacity: 1;
    }
}

@-webkit-keyframes top0-leave {
    0% {
        -webkit-transform: translateY(0%);
        transform: translateY(0%);
        opacity: 1;
        max-height: 200px;
        margin-bottom: 0;
    }
    40% {
        margin-bottom: 0;
        max-height: 200px;
    }
    50% {
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%);
        opacity: 0;
        max-height: 0;
        margin-bottom: 0;
    }
}

@keyframes top0-leave {
    0% {
        -webkit-transform: translateY(0%);
        transform: translateY(0%);
        opacity: 1;
        max-height: 200px;
        margin-bottom: 0;
    }
    40% {
        margin-bottom: 0;
        max-height: 200px;
    }
    50% {
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%);
        opacity: 0;
        max-height: 0;
        margin-bottom: 0;
    }
}

@-webkit-keyframes rotating {
    from {
        transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
    }
}

@keyframes rotating {
    from {
        transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
    }
}

.notifications-container {
    position: fixed;
    z-index: 99999;
}

.notifications-container--tc, .notifications-container--tr, .notifications-container--tl, .notifications-container--bc, .notifications-container--br, .notifications-container--bl {
    width: 360px;
}

.notifications-container--tc .notification--buttons-1 .notification-button, .notifications-container--tr .notification--buttons-1 .notification-button, .notifications-container--tl .notification--buttons-1 .notification-button, .notifications-container--bc .notification--buttons-1 .notification-button, .notifications-container--br .notification--buttons-1 .notification-button, .notifications-container--bl .notification--buttons-1 .notification-button {
    height: 100%;
}

.notifications-container--tc .notification--buttons-2 .notification-button, .notifications-container--tr .notification--buttons-2 .notification-button, .notifications-container--tl .notification--buttons-2 .notification-button, .notifications-container--bc .notification--buttons-2 .notification-button, .notifications-container--br .notification--buttons-2 .notification-button, .notifications-container--bl .notification--buttons-2 .notification-button {
    height: 50%;
}

.notifications-container--tc .notification-buttons, .notifications-container--tr .notification-buttons, .notifications-container--tl .notification-buttons, .notifications-container--bc .notification-buttons, .notifications-container--br .notification-buttons, .notifications-container--bl .notification-buttons {
    flex-direction: column;
}

.notifications-container--tc .notification-buttons .notification-button, .notifications-container--tr .notification-buttons .notification-button, .notifications-container--tl .notification-buttons .notification-button, .notifications-container--bc .notification-buttons .notification-button, .notifications-container--br .notification-buttons .notification-button, .notifications-container--bl .notification-buttons .notification-button {
    display: block;
    width: 100%;
}

.notifications-container--tc .notification-buttons .notification-button:nth-child(2), .notifications-container--tr .notification-buttons .notification-button:nth-child(2), .notifications-container--tl .notification-buttons .notification-button:nth-child(2), .notifications-container--bc .notification-buttons .notification-button:nth-child(2), .notifications-container--br .notification-buttons .notification-button:nth-child(2), .notifications-container--bl .notification-buttons .notification-button:nth-child(2) {
    border-top: 1px solid rgba(0, 0, 0, 0.09);
}

.notifications-container--b .notification-buttons .notification-buttons, .notifications-container--b .notification--buttons-1 .notification-buttons, .notifications-container--t .notification-buttons .notification-buttons, .notifications-container--t .notification--buttons-1 .notification-buttons {
    flex-direction: row;
}

.notifications-container--t {
    width: calc(100% - 40px);
    top: 20px;
    left: 20px;
}

.notifications-container--t .notification-wrapper {
    position: relative;
    top: 0;
}

.notifications-container--t .notification-wrapper-enter {
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-name: top20px-enter;
    animation-name: top20px-enter;
    -webkit-animation-duration: .4s;
    animation-duration: .4s;
}

.notifications-container--t .notification-wrapper-leave {
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-name: top20px-leave;
    animation-name: top20px-leave;
    -webkit-animation-duration: .8s;
    animation-duration: .8s;
}

.notifications-container--tc {
    top: 20px;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
}

.notifications-container--tc .notification-wrapper {
    position: relative;
    top: 0;
}

.notifications-container--tc .notification-wrapper-enter {
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-name: top20px-enter;
    animation-name: top20px-enter;
    -webkit-animation-duration: .4s;
    animation-duration: .4s;
}

.notifications-container--tc .notification-wrapper-leave {
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-name: top20px-leave;
    animation-name: top20px-leave;
    -webkit-animation-duration: .8s;
    animation-duration: .8s;
}

.notifications-container--tr {
    top: 20px;
    right: 20px;
}

.notifications-container--tr .notification-wrapper {
    position: relative;
    right: 0;
}

.notifications-container--tr .notification-wrapper-enter {
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-name: right20px-enter;
    animation-name: right20px-enter;
    -webkit-animation-duration: .4s;
    animation-duration: .4s;
}

.notifications-container--tr .notification-wrapper-leave {
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-name: right20px-leave;
    animation-name: right20px-leave;
    -webkit-animation-duration: .8s;
    animation-duration: .8s;
}

.notifications-container--tl {
    top: 20px;
    left: 20px;
}

.notifications-container--tl .notification-wrapper {
    position: relative;
    left: 0;
}

.notifications-container--tl .notification-wrapper-enter {
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-name: left20px-enter;
    animation-name: left20px-enter;
    -webkit-animation-duration: .4s;
    animation-duration: .4s;
}

.notifications-container--tl .notification-wrapper-leave {
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-name: left20px-leave;
    animation-name: left20px-leave;
    -webkit-animation-duration: .8s;
    animation-duration: .8s;
}

.notifications-container--b {
    width: calc(100% - 40px);
    bottom: 0;
    left: 20px;
}

.notifications-container--b .notification-wrapper {
    position: relative;
    bottom: 0;
}

.notifications-container--b .notification-wrapper-enter {
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-name: bottom20px-enter;
    animation-name: bottom20px-enter;
    -webkit-animation-duration: .4s;
    animation-duration: .4s;
}

.notifications-container--b .notification-wrapper-leave {
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-name: bottom20px-leave;
    animation-name: bottom20px-leave;
    -webkit-animation-duration: .8s;
    animation-duration: .8s;
}

.notifications-container--bc {
    bottom: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
}

.notifications-container--bc .notification-wrapper {
    position: relative;
    bottom: 0;
}

.notifications-container--bc .notification-wrapper-enter {
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-name: bottom20px-enter;
    animation-name: bottom20px-enter;
    -webkit-animation-duration: .4s;
    animation-duration: .4s;
}

.notifications-container--bc .notification-wrapper-leave {
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-name: bottom20px-leave;
    animation-name: bottom20px-leave;
    -webkit-animation-duration: .8s;
    animation-duration: .8s;
}

.notifications-container--br {
    bottom: 0;
    right: 20px;
}

.notifications-container--br .notification-wrapper {
    position: relative;
    right: 0;
}

.notifications-container--br .notification-wrapper-enter {
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-name: right20px-enter;
    animation-name: right20px-enter;
    -webkit-animation-duration: .4s;
    animation-duration: .4s;
}

.notifications-container--br .notification-wrapper-leave {
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-name: right20px-leave;
    animation-name: right20px-leave;
    -webkit-animation-duration: .8s;
    animation-duration: .8s;
}

.notifications-container--bl {
    bottom: 0;
    left: 20px;
}

.notifications-container--bl .notification-wrapper {
    position: relative;
    left: 0;
}

.notifications-container--bl .notification-wrapper-enter {
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-name: left20px-enter;
    animation-name: left20px-enter;
    -webkit-animation-duration: .4s;
    animation-duration: .4s;
}

.notifications-container--bl .notification-wrapper-leave {
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-name: left20px-leave;
    animation-name: left20px-leave;
    -webkit-animation-duration: .8s;
    animation-duration: .8s;
}

@media (max-width: 767px) {
    .notifications-container--t {
        width: 100%;
        top: 0;
        left: 0;
    }

    .notifications-container--t .notification-wrapper {
        position: relative;
        top: 0;
    }

    .notifications-container--t .notification-wrapper-enter {
        -webkit-animation-fill-mode: forwards;
        animation-fill-mode: forwards;
        -webkit-animation-name: top0-enter;
        animation-name: top0-enter;
        -webkit-animation-duration: .4s;
        animation-duration: .4s;
    }

    .notifications-container--t .notification-wrapper-leave {
        -webkit-animation-fill-mode: forwards;
        animation-fill-mode: forwards;
        -webkit-animation-name: top0-leave;
        animation-name: top0-leave;
        -webkit-animation-duration: .8s;
        animation-duration: .8s;
    }

    .notifications-container--t .notification-wrapper {
        margin: 0;
        border-radius: 0;
    }
}

.notification {
    display: flex;
    width: 100%;
    height: 100%;
    min-height: 40px;
    position: relative;
    border: none;
    z-index: 999;
    padding: 0;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
}

.notification-wrapper {
    display: block;
    width: 100%;
    height: 100%;
    margin-bottom: 20px;
}

.notification-image-container {
    box-sizing: border-box;
    padding: 15px 0 15px 15px;
}

.notification-image {
    display: inline-block;
    border-radius: 40px;
    width: 40px;
    height: 40px;
    background-size: cover;
}

.notification-icon {
    display: inline-block;
    box-sizing: border-box;
    margin: 10px 0 10px 15px;
    font-size: 20px !important;
    align-self: flex-start;
}

.notification-meta {
    vertical-align: top;
    box-sizing: border-box;
    width: 100%;
    padding: 10px 20px;
}

.notification-close-button-container {
    padding: 9px 15px;
    box-sizing: border-box;
}

.notification-close-button {
    font-size: 21px;
    font-weight: bold;
    text-shadow: #ffffff 0 1px 0;
    opacity: 0.2;
    cursor: pointer;
}

.notification-close-button:before {
    content: "\D7";
}

.notification-close-button:hover {
    opacity: 0.8;
}

.notification-buttons {
    display: flex;
    box-sizing: border-box;
    vertical-align: top;
}

.notification-buttons .notification-button {
    min-height: 40px;
    box-sizing: border-box;
    padding: 0;
    border-radius: 0;
    background: none;
    outline: none;
    text-align: center;
    cursor: pointer;
}

.notification-buttons .notification-button-text {
    display: block;
    height: 25px;
    min-width: 90px;
    max-width: 150px;
    width: auto;
    padding: 0 15px;
    white-space: nowrap;
    overflow: hidden;
    margin: 0 auto;
    text-overflow: ellipsis;
    text-align: center;
    font-size: 14px;
    line-height: 25px;
}

.notification-title {
    margin: 0 0 10px;
    font-size: 15px;
    font-weight: 700;
}

.notification-message {
    margin: 0;
}

.notification--dismissible {
    cursor: pointer;
}

.notification--default {
    background-color: #f9f9f9;
    border: 1px solid #eeeeee;
    color: #333333;
}

.notification--default .notification-icon {
    display: none;
}

.notification--default .notification-button {
    background: transparent;
    border: none;
    border-left: 1px solid #eeeeee;
    color: #333333;
}

.notification--default .notification-button:hover, .notification--default .notification-button:active {
    color: black;
}

.notification--success {
    background-color: #dff0d8;
    border: 1px solid #d6e9c6;
    color: #3c763d;
}

.notification--success .notification-icon:before {
    content: "\F058";
    color: #3c763d;
}

.notification--success .notification-button {
    background: transparent;
    border: none;
    border-left: 1px solid #d6e9c6;
    color: #3c763d;
}

.notification--success .notification-button:hover, .notification--success .notification-button:active {
    color: #1a321a;
}

.notification--info {
    background-color: #d9edf7;
    border: 1px solid #bce8f1;
    color: #31708f;
}

.notification--info .notification-icon:before {
    content: "\F05A";
    color: #31708f;
}

.notification--info .notification-button {
    background: transparent;
    border: none;
    border-left: 1px solid #bce8f1;
    color: #31708f;
}

.notification--info .notification-button:hover, .notification--info .notification-button:active {
    color: #173543;
}

.notification--warning {
    background-color: #fcf8e3;
    border: 1px solid #faebcc;
    color: #8a6d3b;
}

.notification--warning .notification-icon:before {
    content: "\F071";
    color: #8a6d3b;
}

.notification--warning .notification-button {
    background: transparent;
    border: none;
    border-left: 1px solid #faebcc;
    color: #8a6d3b;
}

.notification--warning .notification-button:hover, .notification--warning .notification-button:active {
    color: #43351c;
}

.notification--error {
    background-color: #f2dede;
    border: 1px solid #ebccd1;
    color: #a94442;
}

.notification--error .notification-icon:before {
    content: "\F057";
    color: #a94442;
}

.notification--error .notification-button {
    background: transparent;
    border: none;
    border-left: 1px solid #ebccd1;
    color: #a94442;
}

.notification--error .notification-button:hover, .notification--error .notification-button:active {
    color: #602625;
}

.notification--loading {
    background-color: #d9edf7;
    border: 1px solid #bce8f1;
    color: #31708f;
}

.notification--loading .notification-icon {
    -webkit-animation: rotating 2s infinite linear;
    animation: rotating 2s infinite linear;
}

.notification--loading .notification-icon:before {
    content: "\F110";
    color: #31708f;
}

.notification--loading .notification-button {
    background: transparent;
    border: none;
    border-left: 1px solid #bce8f1;
    color: #31708f;
}

.notification--loading .notification-button:hover, .notification--loading .notification-button:active {
    color: #173543;
}

.main-container {
    height: calc(100% - (52px + 16px + 26px + 20px + 20px));
    padding-left: 16px !important;
    padding-right: 16px !important;
    /*margin-bottom:50px;*/
}

@media screen and (max-width: 768px) {
    .main-container {
        padding-left: 2px !important;
        padding-right: 2px !important;
    }
}


@media (max-width: 767px) {

    .no-side-padding-for-container {
        padding: 0;
    }
}



/**
* ----------------------------------------------
* Demo styles
* ----------------------------------------------
**/
.accordion {
    border-radius: 20px;
}

.accordion__item + .accordion__item {
    border-radius: 20px;
}

.accordion__item .accordion__button {
    border-radius: 0;
}

.accordion > .accordion__item:first-child .accordion__button {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

.accordion > .accordion__item:last-child .accordion__button {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}

.accordion > .accordion__item:nth-child(odd) .accordion__button {
    background: white;
}

.accordion > .accordion__item:nth-child(even) .accordion__button {
    background: whitesmoke;
}

.accordion > .accordion__item:nth-child(odd) .accordion__panel {
    background: white;
}

.accordion > .accordion__item:nth-child(even) .accordion__panel {
    background: whitesmoke;
}

.accordion > .accordion__item:last-child .accordion__panel {
    padding-top: 16px;
    margin-top: -16px;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
}

.accordion__button {
    background-color: #f4f4f4;
    color: #444;
    cursor: pointer;
    padding: 10px;
    width: 100%;
    text-align: left;
    border: none;
}

.accordion__button:hover {
    background-color: #ddd;
}

.accordion__panel {
    padding: 10px 20px;
}

.modal-open {
    overflow: hidden;
}

.ReactModal__Overlay {
    z-index: 1000;
}

.ReactModal__Overlay--after-open {
    background-color: rgba(0, 0, 0, 0.5);
}

.modal-dialog {
    -webkit-transform: translateZ(0);
    overflow: hidden;
    box-shadow: 0 3px 9px rgba(0,0,0,.5);
    background: #e6f1ee;
    border-radius: 20px;
    background-clip: padding-box;
    -webkit-background-clip: padding-box;
    max-height:92%;
}

.modal .modal-content {
    box-shadow: none;
    border: none;
}

.modal .modal-header .close {
    padding-top: 5px;
    color: rgba(0,0,0,1);
}

.modal .modal-header {
    background-color: var(--border-color-light);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

.modal .modal-body > .modal-footer {
    border: 1px solid green;
}

.modal .modal-footer {
    background-color: var(--border-color-light);
}

.full-width {
    width: 95%;
}

.modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto;
}

.modal-dialog:focus {
    outline: none;
}

.modal-body {
    max-height: calc(100vh - 200px);
    overflow-y: auto;
}


@media (min-width: 900px) {
    .modal-lg {
        width: 90%;
    }
}

@media (min-width: 1200px) {
    .modal-lg {
        width: 75%;
    }
}


@media screen and (min-width: 768px) {
    .modal-dialog {
        box-shadow: 0 5px 15px rgba(0,0,0,.5);
    }
}

@media screen and (max-width: 768px) {
    .full-width {
        width: 100%;
        margin: 0;
        max-height: 100%;
        height: 100%;
    }
    .modal-dialog {
        border-radius: 0;
    }

    .modal-body {
        position: relative;
        padding-right: 0;
        padding-left: 0;
        max-height: calc(100vh - 55px);
        overflow-y: auto;
    }

    .modal-content {
        height: 100%;
        border-radius: 0;
        border: none;
    }
}

.home-container {
    padding-top: 36px;
}

.centered-main-navigation {
    padding-top: 50px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    width: 100%;
    height: calc(100%);
    min-height: 450px;
    /* background: url('./medBG2.png'); */
    background-size: cover;
}

.centered-main-navigation .jumbotron {
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 10px;
    background: none;
}

.doctor-image {
    height: 110px;
    width: 110px;
    border: 6px solid var(--secondary-color);
    border-radius: 16px;
    background: white;
    margin: 10px auto;
    color: var(--secondary-color);
    overflow: hidden;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.doctor-image > span {
    margin-bottom: -8px;
    color: var(--secondary-color)
}

.greetings {
    order: 0;
    flex: 0 0 auto;
    align-self: center;
}

.main-content {
    margin-bottom: 50px;
    order: 0;
    flex: 1 0 auto;
    align-self: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.main-content .flex-inner-item {
    order: 0;
    flex: 0 0 auto;
    align-self: center;
    margin: auto 16px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.main-navigation-button-container {
    margin: 10px auto;
    height: 160px;
    width: 160px;
}

.main-navigation-button-container a:focus, a:hover{
    text-decoration: none;
    outline: none;
}

.main-navigation-button {
    width: 100%;
    height: 100%;
    background-image: -webkit-linear-gradient(top, #03c1af 0, #028578 100%);
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, #03c1af), to(#028578));
    background-image: linear-gradient(to bottom, #03c1af 0, #028578 100%);
    border-radius: 42%;
    color: white;
    padding: 32px;
    font-size: 20px;
    border: none;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-content: center;
    align-items: center;
}

.main-navigation-button > span {
    color: white;
}

.responsive-icon {
    font-size: 6rem;
}

.breadcrumb {
    margin: 0;
}

.breadcrumbs {
    position: fixed;
    z-index: 100;
    width: 100%;
}

.breadcrumb .right-field {
    float: right;
}


.navbar-nav .double-line-navigation > div > li > a {
    color: var(--text-color);
}

.navbar-nav .double-line-navigation > div > li > a:focus {
    text-decoration: none;
}

.navbar-nav .double-line-navigation > div > li >  .active-nav {
    font-size: unset;
}

.double-line-navigation {
    margin-top: 6px;
    margin-bottom: 6px;
}
.double-line-navigation .secondary-info {
    font-size: smaller;
    text-align: center;
}






@media screen and (max-width: 992px) {
    .navbar-form > .input-group > .form-control {
        width: 110px;
    }
}

@media screen and (max-width: 768px) {

    .responsive-icon {
        font-size: 4rem;
    }

    .greetings > h2 {
        font-size: 20px;
    }

    .doctor-image {
        height: 100px;
        width: 100px;
    }

    .doctor-image > span {
        font-size: 7rem;
    }

    .flex-item:nth-child(2) {
        flex-direction: row;
        flex-wrap: wrap;
    }
    .main-navigation-button-container {
        width: 120px;
        height: 120px;
    }
    .main-navigation-button {
        padding: 16px;
        width: 120px;
        height: 120px;
    }

    .main-navigation-button > div {
        font-size: 14px;
    }
}

@media (max-width: 400px) {
    .home-container {
        padding-top: 0;
    }
    .home-container > div {
        padding-top: 50px;
    }

    .breadcrumb {
        padding-left: 50px;
    }
}

.grouped-inputs {
    width: 100%;
    background: white;
    border: 1px solid #ccc;
    box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
    padding: 6px 12px;
    border-radius: 20px;
    float: left;
    margin-bottom: 0;
}

.grouped-input > input {
    width: auto !important;
    border: none;
    box-shadow: none;
    padding-left: 1px;
}

.grouped-input > select {
    width: auto !important;
    border: none;
    box-shadow: none;
    padding-left: 1px;
}

.grouped-input > input:focus {
    outline: none;
    box-shadow: none;
}

.grouped-input > select:focus {
    outline: none;
    box-shadow: none;
}

.grouped-input > .input-group-addon {
    padding: 0;
    border: none;
    background: none;
}

.grouped-input > .input-group-addon > span {
    background: #ccc;
    border: 1px solid #ccc;
    border-radius: 2px;
    font-size: smaller;
    padding: 2px
}

/*.label-margin > input{*/
    /*margin-right: 4px;*/
/*}*/

/*.label-align-right{*/
    /*text-align: right;*/
/*}*/

/*.list-close-icon {*/
    /*margin-top: 2px;*/
    /*color: red;*/
/*}*/
/*.list-add-icon {*/
    /*margin-top: 2px;*/
    /*font-weight: bolder;*/
    /*color: #1e87f0;*/
/*}*/

/*.icon-button{*/
    /*cursor: pointer;*/
/*}*/

/*.selection-list {*/
    /*padding-top: 7px;*/
    /*padding-bottom: 4px;*/
    /*margin-bottom: 2px;*/
    /*margin-top:2px;*/
/*}*/

/*.selection-list .placeholder{*/
    /*color: #dddddd;*/
/*}*/

/*@media only screen and (max-width: 959px) {*/
    /*.label-align-right{*/
        /*text-align: left;*/
    /*}*/
/*}*/


/*.autocomplete-dropdown-container{*/
    /*position: absolute;*/
    /*z-index: 1020;*/
    /*box-sizing: border-box;*/
    /*min-width: 200px;*/
    /*padding: 25px;*/
    /*background: #fff;*/
    /*color: #666;*/
    /*box-shadow: 0 5px 12px rgba(0,0,0,0.15);*/
/*}*/

/*.autocomplete-list-suggestion:hover{*/
    /*cursor: pointer;*/
    /*transition: color 0.2s ease;*/
    /*color: #00198d;*/
/*}*/

/*.autocomplete-list {*/
    /*margin-bottom: 0;*/
/*}*/

/*.uk-textarea {*/
    /*resize: vertical;*/
    /*border-radius: 10px;*/
/*}*/

/*.uk-select, .uk-button, .uk-input {*/
    /*border-radius: 10px;*/
/*}*/

.form-control {
    border-radius: 20px;
}

/*TODO Put caret back in place... those borders, needed to get a hack to round select, but caret dissapeared.*/

select.form-control {
    -webkit-appearance: none;
    -moz-appearance: none;
    background-position: right 50%;
    background-repeat: no-repeat;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAMCAYAAABSgIzaAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NDZFNDEwNjlGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NDZFNDEwNkFGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo0NkU0MTA2N0Y3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo0NkU0MTA2OEY3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuGsgwQAAAA5SURBVHjaYvz//z8DOYCJgUxAf42MQIzTk0D/M+KzkRGPoQSdykiKJrBGpOhgJFYTWNEIiEeAAAMAzNENEOH+do8AAAAASUVORK5CYII=);
}

select::-ms-expand {
    width:12px;
    border:none;
    background:#fff;
}

input[type="date"].form-control {
    line-height: 20px;
}

input[type='date'], input[type='time'] {
    -webkit-appearance: none;
}

input[type="checkbox"] {
    margin-right: 4px;
}

.radio-inline {
    margin-left: 10px;
}

.grouped-item {
    margin-bottom: 0;
}

.grouped-item .form-control {
    border-radius: 0;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.disabled-input {
    height: 24px;
    border-bottom: 1px solid var(--border-color);
    padding-top: 5px;
    margin-bottom: 0;
    text-align: left;
    font-size: 14px;
}

.disabled-input .text-muted {
    font-size: 14px;
    opacity: 0.5;
}

.disabled-input.warning {
    border-color: var(--attention-color);
}
.disabled-input.warning:after {
    color: var(--attention-color);
    min-height: 22px;
    margin-top: -5px;
    margin-right: 8px;
    float: right;
    font-family: "Font Awesome 5 Free"; font-weight: 900; content: "\F071";
}

.radio-inline .disabled-input {
    padding-top: 0;
    margin-left: -30px;
}

.rounded-left {
    border-top-left-radius: 20px !important;
    border-bottom-left-radius: 20px !important;
}


.patient-search-container {
    
    padding: 20px;
    border-radius: 20px;
    min-height: unset;
}

.patient-search-container h3 {
    color: white;
    margin-top: 0;
}

.active-nav {
    color: #66acf6 !important;
    font-weight: 900;
    font-size: larger;
    border-bottom: 2px solid #66acf6;
}

.navbar {
    margin-bottom:0;
}

.navbar-brand {
    padding-top: 0;
}

.navbar-fixed-top {
    z-index: 999;
}

.navbar-brand img {
    padding-top: 6px;
    padding-bottom: 6px;
    width: 50px;
    height: 50px;
}

.navbar .active {
    color: #66acf6 !important;

}

.navbar-text {
    padding-left: 4px;
    padding-right: 4px;
}


.navbar-left {
    margin-left: 10px
}

.navbar-left > li {
    height: 50px;
}

.navbar-nav>li>a {
    height: 50px;
    padding-top: 15px;
    padding-bottom: 10px;
}

.navbar-right > li > a {
    padding-top: 10px;
}

.navbar-nav>li>a>p {
    margin-top: 5px;
    margin-bottom: 5px;
}


@media (max-width: 767px) {

    .navbar-left {
        margin-left: 0
    }
    .navbar-nav {
        margin: auto;
    }

    .navbar-form {
        margin-right: 0
    }

    .navbar .navbar-form .input-group > input {
        width: 100%;
    }

    .double-line-navigation {
        padding-left: 15px;
    }

    .double-line-navigation > .secondary-info {
        text-align: left;
    }
}


.contacts-container {
    height: calc(100%);
    padding: 0;
    display: flex;
    flex-direction: row;
}

.contacts-container a:focus {
    text-decoration: none;
}
.select-patient {
    height: 100%;
    margin: 0;
    background: whitesmoke;
    display: flex;
    flex-direction: column;
    flex: 1 1;
    align-items: center;
    justify-content: center;
}

.contacts-container .side-container {
    padding-left: 0;
    padding-right: 0;
    padding-top: 10px;
    margin-left: 0;
    margin-right: 0;
    border-right: 1px solid var(--border-color);
    height: 100%;
    overflow-y: hidden;
}

.search-container {
    position: absolute;
}

.contacts-container .side-container .selector-content > .nav {
    padding-top: 50px;
    margin-left: 10px;
}

.contacts-container .side-container .nav > .pull-right {
    padding-top: 8px;
    margin-right: 14px;
    height: 42px;
}

.contacts-container .side-container .nav .pull-right > .btn {
    padding: 3px 8px;
}

.contacts-container .side-container .selector-content {
    height: 100%;
    width: 100%;
}

.contacts-container .side-container .tab-content {
    height: calc(100% - 100px);
    overflow-y: scroll;
    overflow-x: hidden;
}

.contacts-container .side-container .tab-content > div {
    margin-left: 10px;
    margin-right: -15px;
    padding-right: 15px;
}

.contact-card {
    background: var(--selection-background-color);
    border: 1px solid var(--border-color);
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: space-between;
    align-items: center;
    margin-bottom: 6px;
    border-radius: 16px;
    padding: 4px 8px;
}

a.active > .contact-card {
    background: white;
    box-shadow: 2px 2px 5px 0 var(--box-shadow-color);
}

.contact-card .content {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: space-between;
    align-items: center;
    color: var(--text-color);
}

.contact-card:hover {
    box-shadow: 2px 2px 5px 0 var(--box-shadow-color);
}

.contact-card .content .photo-container {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 6px;
    text-align: center;
}

.contact-card .content .photo-container .photo {
    border-radius: 50%;
    overflow: hidden;
    border: 1px solid var(--primary-faded-color);
    box-shadow: 0 0 0 1px var(--primary-faded-color);
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    order: 0;
    flex: 0 1 auto;
    align-self: auto;
    width: 35px;
    height: 35px;
    margin-right: 6px;
}

.contact-card .content .photo-container .photo.professional {
    border-radius: 5px;
}

.contact-card.active .content .photo-container .photo {
    box-shadow: 0 0 0 4px var(--primary-faded-color);
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.contact-card .content .information {
    order: 0;
    flex: 1 2 auto;
    align-self: center;
}

.contact-card .content .information .gender.fa-venus {
    margin-right: 4px;
    color: hotpink;
}

.contact-card .content .information .gender.fa-mars {
    margin-right: 4px;
    color: dodgerblue;
}

.contact-card .content .information .name {
    font-size: larger;
}



.contact-card .buttons {
    order: 0;
    flex: 1 2 auto;
    align-self: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-content: center;
    align-items: center;

}

.contact-card .buttons > * {
    background: none;
    border: none;
    margin-left: 16px;
    margin-right: 16px;
}

.contact-card .buttons > * > * {
    background: none;
    border: none;
    color: var(--text-color);
}


@media screen and (max-width: 768px) {
    .contact-card {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: center;
        align-content: center;
        align-items: center;
    }

    .contact-card .buttons {
        height: 64px;
    }
}

/*Tabs Buttons Styling*/
.nav-tabs>li.active>button, .nav-tabs>li.active>button:focus, .nav-tabs>li.active>button:hover {
    color: #555;
    cursor: default;
    background-color: #fff;
    border: 1px solid #ddd;
    border-bottom-color: transparent;
}

.nav-tabs>li>button {
    margin-right: 2px;
    line-height: 1.42857143;
    border: 1px solid transparent;
    border-radius: 20px 20px 0 0;
}

.nav>li>button {
    position: relative;
    display: block;
    padding: 10px 15px;
}

.nav-tabs>li>button {
    background-color: transparent;
    color: #337ab7;
    margin-right: 2px;
    line-height: 1.42857143;
    border: 1px solid transparent;
    border-radius: 20px 20px 0 0;
}

.nav>li>button {
    position: relative;
    display: block;
    padding: 10px 15px;
}

.nav-tabs>li>button:hover {
    border-color: #eee #eee #ddd;
}

.nav>li>button:focus, .nav>li>button:hover {
    outline: none;
    text-decoration: none;
    background-color: #eee;
}

/*NavBar Buttons Styling*/

/*NavBar Pills Styling */

.nav-pills>li.active>button, .nav-pills>li.active>button:focus, .nav-pills>li.active>button:hover {
    color: #fff !important;
    background-color: #337ab7;
}

.nav-pills>li>button {
    border-radius: 20px;
}

.nav>li>button {
    position: relative;
    display: block;
    padding: 10px 15px;
}

.nav-pills>li>button {
    border-radius: 20px;
    border: none;
}
.nav>li>button {
    position: relative;
    display: block;
    padding: 10px 15px;
}


/*NavBar Pills Styling */


/*Navbar Justified Styling*/

.nav-tabs.nav-justified {
    width: 100%;
    border-bottom: 0;
}
.nav-pills.nav-justified {
    width: 100%;
}
.nav-justified {
    width: 100%;
}

.nav-tabs.nav-justified>li {
    float: none;
}
.nav-pills.nav-justified>li {
    float: none;
}
.nav-justified > li > button {
    width: 100%;
}

@media (min-width: 768px) {
    .nav-tabs.nav-justified>.active>button, .nav-tabs.nav-justified>.active>button:focus, .nav-tabs.nav-justified>.active>button:hover {
        border-bottom-color: #fff;
    }

    .nav-tabs.nav-justified>li>button {
        margin-bottom: 0;
    }

    .nav-tabs.nav-justified>li>button {
        border-bottom: 1px solid #ddd;
        border-radius: 20px 20px 0 0;
    }
    .nav-pills.nav-justified>li>button {
        border-radius: 20px;
    }

    .nav-justified>li>button {
        margin-bottom: 0;
    }

    .nav-tabs.nav-justified>li {
        display: table-cell;
        width: 1%;
    }
    .nav-pills.nav-justified>li {
        display: table-cell;
        width: 1%;
    }

    .nav-justified>li>button {
        margin-bottom: 5px;
        text-align: center;
    }
}

.nav-tabs.nav-justified>.active>button, .nav-tabs.nav-justified>.active>button:focus, .nav-tabs.nav-justified>.active>button:hover {
    border: 1px solid #ddd;
    border-bottom: #fff;
}

.nav-tabs.nav-justified>li>button {
    margin-right: 0;
    border-radius: 20px;
}
.nav-pills.nav-justified>li>button {
    margin-right: 0;
    border-radius: 20px;
}

.nav-tabs.nav-justified>li>button {
    border-bottom: 1px solid #ddd;
    border-radius: 20px 20px 0 0;
}

.nav-tabs.nav-justified>li>button {
    margin-bottom: 5px;
    text-align: center;
}

.nav-pills.nav-justified>li>button {
    margin-bottom: 5px;
    text-align: center;
}

/*Navbar Justified Styling*/

.user-container {
    overflow-y: hidden;
    background: whitesmoke;
    padding: 0 6px 6px 6px;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: center;
    align-items: stretch;
}

.user-container .id-container {
    border: 1px solid var(--border-color-light);
    margin: 0  auto 6px auto;
    border-radius: 20px;
    width: 100%;
    order: 0;
    flex: 0 1 auto;
    align-self: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
}

.user-container .id-container .photo {
    order: 0;
    flex: 0 0 auto;
    align-self: auto;
    height: 120px;
    width: 120px;

    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 6px;
    text-align: center;
}

.user-container .id-container .photo .profile-photo {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
    border: 4px solid var(--primary-color);
    box-shadow: 0 0 0 4px var(--primary-faded-color);
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    order: 0;
    flex: 0 1 auto;
    align-self: auto;
}

.user-container .id-container .information {
    order: 0;
    flex: 1 1 auto;
    align-self: auto;
    padding-right: 6px;
}

.user-container .id-container .information div.disabled {
    cursor: default;
}

.user-container .id-container .information .btn{
    margin-left: 1px;
    margin-right: 1px;
}

.user-container .id-container .information .name {
    font-size: larger;
}

.user-container .id-container .information .gender.fa-venus {
    margin-right: 4px;
    color: hotpink;
}


.user-container .id-container .information .gender.fa-mars {
    margin-right: 4px;
    color: dodgerblue;
}

.user-container .id-container .information h3 {
    margin-top: 0;
}

.user-container .id-container .information > div, h3 {
    margin-left: 1px;
}

.user-container .patient-actions {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-content: center;
    align-items: center;
}

.user-container .clinical-data-container {
    margin: 6px  auto;
    width: 100%;
    border: 1px solid var(--border-color-light);
    border-radius: 20px;
    order: 0;
    flex: 0 1 auto;
    align-self: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.user-container .clinical-data-container .icon {
    margin: auto 50px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 6px;
    text-align: center;
}

.user-container .clinical-data-container .icon > span {
    width: 50px;
    height: 50px;
    color: var(--light-blue);
    padding-top: 5px;
    font-size: 3rem;
    border-radius: 50%;
    overflow: hidden;
    border: 4px solid var(--light-blue);
    box-shadow: 0 0 0 2px var(--box-shadow-color);
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    order: 0;
    flex: 0 1 auto;
    align-self: auto;
}

.user-container .events-container {
    overflow-y: scroll;
    order: 0;
    flex: 1 1 auto;
    align-self: auto;
}

.user-container .events-container .events {
    height: 100%;
}

.user-container .actions-container {
    border: 1px solid var(--border-color-light);
    border-radius: 20px;
    order: 0;
    flex: 0 1 auto;
    align-self: auto;
}

.user-container .actions-container .buttons {
    margin: 30px auto;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-content: center;
    align-items: center;
}

.user-container .actions-container .buttons .btn {
    padding-left: 24px;
    padding-right: 24px;
}

.user-container .clinical-data-container {
    padding: 8px;
}

.user-container .clinical-data-container .icon.with-allergy > span {
    color: var(--attention-color);
    border-color: var(--attention-color);
}

.footer-checkbox-container .form-group{
    margin-bottom: 0;
}

.footer-checkbox-container .form-group .checkbox{
    text-align: left;
    margin-bottom: 0;
    margin-top: 0;
}

@media (max-width: 768px) {
    .user-container .clinical-data-container .icon {
        margin: auto 5%;
    }
    .user-container .actions-container .buttons {
        flex-wrap: wrap;
    }

    .user-container .actions-container .buttons .btn {
        padding-left: 36px;
        padding-right: 36px;
    }


    .user-container .actions-container .buttons > * {
        margin-top: 5px;
        margin-bottom: 5px;
    }
}

.page-header.margin-small {
    margin: 8px 0 4px 0;
    padding-bottom: 3px;
}

.page-header > * > span.pull-right {
    margin-top: -5px;
}

.page-header.no-hr {
    border-bottom: none;
}

.form-inline > * {
    margin-right: 8px;
    margin-bottom: 8px;
}

.two-line-group {
    height: 48px;
}

.two-line-group .input-group-addon > * {
    margin-top: 500px
}

.two-line-group .form-control {
    height: 48px;
}

.two-line-group .title {
    font-size: 100%;
}

.two-line-group .subtext {
    font-size: 80%;
    color: grey;
}

.pathology-addon {
    background: #cef2ff;
}

.text-right {
    display:inline-block;
    float: right;
    margin-right: 10px;
}


.row{
    line-height: 42px;
}

.panel-heading {
    min-height: 42px;
    padding-top: 0;
    padding-bottom: 0;
}

.panel-info {
    border-color: #d9edf7;
}
.panel-info>.panel-heading {
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, #eef7fb), to(#d9edf7));
    background-image: -webkit-linear-gradient(top, #eef7fb 0, #d9edf7 100%);
    background-image: linear-gradient(to bottom, #eef7fb 0, #d9edf7 100%);
}

.panel-footer > * {
    margin-right: 16px;
}

.panel-footer > .pull-right {
    margin-right: 0;
}

.panel.rounded-corners .panel-heading {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    padding: auto 10px;
}

.panel.rounded-corners .panel-footer {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    padding: auto 10px;
}

.rounded-corners {
    border-radius: 20px;
}

.panel-warning-attention > .panel-heading {
    background-image: -webkit-linear-gradient(top, #eeb2a1 0%, #eaa08b 100%);
    background-image: -webkit-gradient(linear, left top, left bottom, from(#eeb2a1), to(#eaa08b));
    background-image: linear-gradient(to bottom, #eeb2a1 0%, #eaa08b 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffeeb2a1', endColorstr='#ffeaa08b', GradientType=0);
    background-repeat: repeat-x;
}

.panel-warning-attention {
    border-color: #ea908b;
}
.panel-warning-attention > .panel-heading {
    color: #000000;
    background-color: #eeb2a1;
    border-color: #ea908b;
}
.panel-warning-attention > .panel-heading + .panel-collapse > .panel-body {
    border-top-color: #ea908b;
}
.panel-warning-attention > .panel-heading .badge {
    color: #eeb2a1;
    background-color: #000000;
}
.panel-warning-attention > .panel-footer + .panel-collapse > .panel-body {
    border-bottom-color: #ea908b;
}


.panel-warning > .panel-heading {
    background-image: -webkit-linear-gradient(top, #f5e79e 0%, #f3e086 100%);
    background-image: -webkit-gradient(linear, left top, left bottom, from(#f5e79e), to(#f3e086));
    background-image: linear-gradient(to bottom, #f5e79e 0%, #f3e086 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff5e79e', endColorstr='#fff3e086', GradientType=0);
    background-repeat: repeat-x;
}

.panel-warning {
    border-color: #f3d086;
}
.panel-warning > .panel-heading {
    color: #544324;
    background-color: #f5e79e;
    border-color: #f3d086;
}
.panel-warning > .panel-heading + .panel-collapse > .panel-body {
    border-top-color: #f3d086;
}
.panel-warning > .panel-heading .badge {
    color: #f5e79e;
    background-color: #8a6d3b;
}
.panel-warning > .panel-footer + .panel-collapse > .panel-body {
    border-bottom-color: #f3d086;
}

.text-align-right {
    text-align: right;
}

.prescriber-cell {
    min-width: 150px;
}

.date-cell {
    min-width: 100px;
}

.medication-list .separator-line > td {
    padding-top: 3px;
    padding-bottom: 3px;
    font-size: 75%;
    font-weight: 900;
}

.timeline {
    position: relative;
    overflow: auto;
}
.timeline:before {
    content: '';
    position: absolute;
    height: 100%;
    width: 5px;
    background: var(--secondary-color);
    left: 0;
}
.timeline h3 {
    border-radius: 100px;
    background: var(--secondary-color);
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    color:white;
    margin: 0 auto 1em;
    padding: 4px 12px;
    text-align: center;
    position: relative;
    clear: both;
    font-size: 18px;
}

.timeline h4{
    font-size: 16px;
}

.timeline h4 span {
    font-weight:bold;
}
.timeline ul {
    list-style: none;
    padding: 0 0 0 20px;
    z-index: 1;
}
.timeline li {
    border-radius: 0 15px 15px 15px;
    padding: 8px;
    margin-bottom: 8px;
    position: relative;
}
.timeline li:before {
    content: '';
    width: 0;
    height: 0;
    border-top: 1em solid #efefef;
    border-left: 1em solid transparent;
    position: absolute;
    left: -1em;
    top: 0;
}
.timeline h4 {
    margin-top: 0;
}
.timeline time {
    font-size:14px;
    font-weight: 300;
}

@media screen and (min-width: 40em) {
    .timeline:before {
        left: 50%;
    }
    .timeline ul {
        padding-left: 0;
        max-width: 100%;
        margin: 0 auto;
    }
    .timeline li {
        width: 45%;
    }
    .timeline li:nth-child(even) {
        float: right;
        border-top-left-radius: 0;
        margin-top: 25px;
    }
    .timeline li:nth-child(odd) {
        border-top-left-radius: 15px;
        float: left;
    }
    .timeline li:nth-child(odd):before {
        border-top: 1em solid #efefef;
        border-right: 1em solid transparent;
        right: -1em;
        left: auto;
    }
    .timeline li:nth-of-type(2n+1) {
        clear: both;
    }
}

.timeline .red {
    color: #d82929;
    background-color: #f0abab;
    border-color: #ec9595;
}

.timeline .red:before {
    content: '';
    width: 0;
    height: 0;
    border-top: 1em solid #f2dede;
    border-left: 1em solid transparent;
    position: absolute;
    left: -1em;
    top: 0;
}

.timeline .red:before {
    content: '';
    width: 0;
    height: 0;
    border-top: 1em solid #f2dede !important;
    border-left: 1em solid transparent;
    position: absolute;
    left: -1em;
    top: 0;
}

.timeline .red:nth-child(odd):before{
    border-top: 1em solid #f2dede !important;
    border-right: 1em solid transparent;
    right: -1em;
    left: auto;
}

.timeline .green {
    border-color: #d6e9c6;
    background-color: #dff0d8;
    color: #609238;
}

.timeline .green:before {
    content: '';
    width: 0;
    height: 0;
    border-top: 1em solid #dff0d8;
    border-left: 1em solid transparent;
    position: absolute;
    left: -1em;
    top: 0;
}


.timeline .green:before {
    content: '';
    width: 0;
    height: 0;
    border-top: 1em solid #dff0d8 !important;
    border-left: 1em solid transparent;
    position: absolute;
    left: -1em;
    top: 0;
}

.timeline .green:nth-child(odd):before{
    border-top: 1em solid #dff0d8 !important;
    border-right: 1em solid transparent;
    right: -1em;
    left: auto;
}

.timeline .purple {
    color: #6b48c8;
    background-color: #bbabe6;
    border-color: #ab97e0;
}

.timeline .purple:before {
    content: '';
    width: 0;
    height: 0;
    border-top: 1em solid #bbabe6;
    border-left: 1em solid transparent;
    position: absolute;
    left: -1em;
    top: 0;
}


.timeline .purple:before {
    content: '';
    width: 0;
    height: 0;
    border-top: 1em solid #bbabe6 !important;
    border-left: 1em solid transparent;
    position: absolute;
    left: -1em;
    top: 0;
}

.timeline .purple:nth-child(odd):before{
    border-top: 1em solid #bbabe6 !important;
    border-right: 1em solid transparent;
    right: -1em;
    left: auto;
}

.timeline .orange {
    color: #ffa302;
    background-color: #ffe4b4;
    border-color: #ffdb9a;
}

.timeline .orange:before {
    content: '';
    width: 0;
    height: 0;
    border-top: 1em solid #ffe4b4;
    border-left: 1em solid transparent;
    position: absolute;
    left: -1em;
    top: 0;
}


.timeline .orange:before {
    content: '';
    width: 0;
    height: 0;
    border-top: 1em solid #ffe4b4 !important;
    border-left: 1em solid transparent;
    position: absolute;
    left: -1em;
    top: 0;
}

.timeline .orange:nth-child(odd):before{
    border-top: 1em solid #ffe4b4 !important;
    border-right: 1em solid transparent;
    right: -1em;
    left: auto;
}


.timeline .yellow {
    color: #b2a400;
    background-color: #fff9af;
    border-color: #fff795;
}

.timeline .yellow:before {
    content: '';
    width: 0;
    height: 0;
    border-top: 1em solid #fff795;
    border-left: 1em solid transparent;
    position: absolute;
    left: -1em;
    top: 0;
}

.timeline .yellow:before {
    content: '';
    width: 0;
    height: 0;
    border-top: 1em solid #fff795 !important;
    border-left: 1em solid transparent;
    position: absolute;
    left: -1em;
    top: 0;
}

.timeline .yellow:nth-child(odd):before{
    border-top: 1em solid #fff795 !important;
    border-right: 1em solid transparent;
    right: -1em;
    left: auto;
}

.doctor-container {
    background: whitesmoke;
    padding: 0 6px 6px 6px;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: center;
    align-items: stretch;
}

.doctor-container .id-container {
    border: 1px solid var(--border-color-light);
    margin: 0  auto 6px auto;
    border-radius: 20px;
    width: 100%;
    order: 0;
    flex: 0 1 auto;
    align-self: auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
}

.doctor-container .id-container .photo {
    order: 0;
    flex: 0 0 auto;
    align-self: auto;
    height: 120px;
    width: 120px;

    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 6px;
    text-align: center;
}

.doctor-container .id-container .photo .profile-photo {
    width: 100px;
    height: 100px;
    border-radius: 20px;
    overflow: hidden;
    border: 4px solid var(--primary-color);
    box-shadow: 0 0 0 4px var(--primary-faded-color);
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    order: 0;
    flex: 0 1 auto;
    align-self: auto;
}

.doctor-container .id-container .information {
    order: 0;
    flex: 1 1 auto;
    align-self: auto;
    padding-right: 6px;
}

.doctor-container .id-container .information .name {
    font-size: x-large;
}

.doctor-container .id-container .information .number {
    cursor: default;
    font-size: unset;
    text-align: center;
}

.doctor-container .id-container .information .secondary-info {
    margin-top: 10px;
    margin-bottom: 10px;
}

.doctor-container .id-container .information .secondary-info > * {
    margin-top: 10px;
    margin-bottom: 10px;
}

.doctor-container .id-container .information h3 {
    margin-top: 0;
}

.doctor-container .id-container .information > div, h3 {
    margin-left: 1px;
}

.doctor-container .actions-container {
    border: 1px solid var(--border-color-light);
    border-radius: 20px;
    order: 0;
    flex: 0 1 auto;
    align-self: auto;
}


.doctor-container .actions-container .buttons {
    margin: 30px auto;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-content: center;
    align-items: center;
}

.doctor-container .actions-container .buttons .btn {
    padding-left: 24px;
    padding-right: 24px;
}

.patient-insertion .unaligned-fields {
    padding-right: 36px;
}


@media (max-width: 768px) {
    .patient-insertion {
        padding-left: 0;
        padding-right: 0;
    }

    .patient-insertion > * {
        padding-left: 0;
        padding-right: 0;
    }
}

.margin-bottom {
    margin-bottom: 8px;
}

.messages-window-container {
    height: calc(100% - 10px);
}

.messages {
    height: 100%;
    margin-bottom: 0;
}

.messages .panel-body {
    padding-left: 0;
    padding-right: 0;
}

.messages .panel-body > :first-child {
    padding-left: 8px;
    padding-right: 8px;
}

.messages .message-item {
    border-left: none;
    border-right: none;
    border-radius: 0;
}

.message-item:hover {
    cursor: pointer;
    background: whitesmoke;
}


.conversation-line li {
    list-style: none;
}

.conversation-container {
    overflow-y: scroll;
    height: calc(100% - 56px);
}

.send-container {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
}

.message {
    margin: 10px auto;
}

.message > div > .left-side, .right-side {
    border-radius: 16px;
    padding: 4px 12px;
}

.left-side {
    background: lightgray;
}

.right-side {
    color: white;
    background: #5e94ca;
}

.small-text {
    font-size: x-small;
}

.small-text-left {
    margin-left: 4px;
}


.small-text-right {
    margin-right: 4px;
}

.send-message-box > .textarea {
    resize: vertical;
    width: calc(100% - 68px) !important;
    margin-right: 2px;
}

.send-message-box button {
    height: 54px
}

.qr-container {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    padding-bottom: 100px;
}

.qr-container .jumbotron {
    border-radius: 20px;
    padding: 5%;
    order: 0;
    flex: 1 1 auto;
}

.qr-container .qr-code-box {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: center;
    align-items: center;
    padding: 10px;
    background: white;
    border-radius: 20px;
    margin-bottom: 50px;
    margin-top: 20px;
}

.qr-container .qr-code-box img {
    width: 250px;
    height: 250px;
}

.flex-container {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-content: center;
    align-items: center;
}

.qr-container .jumbotron {
    order: 0;
    flex: 0 1 auto;
    align-self: auto;
}

.qr-container .qr-code-box {
    order: 0;
    flex: 0 1 auto;
    align-self: auto;
}

.qr-container .jumbotron p:last-child{
    margin-bottom: 0;
}

@media screen and (max-width: 768px) {
    .qr-container .jumbotron {
        padding: 20px;
    }
    .qr-container .jumbotron h2 {
        font-size: 22px;
    }
    .qr-container .jumbotron br {
        display: none;
    }
    .qr-container .jumbotron p{
        font-size: 18px;
        padding-top: 10%;
        padding-bottom: 0;
        flex-direction: column;
    }
    .qr-container .qr-code-box {
        margin-left: 0;
    }
}

.profile-container {
    padding-left: 0;
    padding-right: 0;
}

.profile-container .side-menu .list-item-with-icon {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    padding: 12px 8px;
}

.profile-container .side-menu .list-item-with-icon > span {
    width: 46px;
    text-align: center;
    order: 0;
    flex: 0 0 auto;
    align-self: auto;
}

.profile-container .side-menu .list-item-with-icon > .text {
    width: 100%;
    order: 0;
    flex: 0 0 auto;
    align-self: center;
}

.no-favorites {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    align-items: center;
    justify-content: center;
}

.favorite-footer {
    padding-left: 16px;
    margin-bottom: 4px;
    padding-bottom: 4px;
}

.favorite-box .static-medicine {
    margin-bottom: 0;
}

.favorite-box ul {
    margin-bottom: 0;
}

.u-position-absolute {
    position: absolute;
}

.u-position-relative {
    position: relative;
}

@-webkit-keyframes fadein {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadein {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-webkit-keyframes move-down {
    0% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
    10% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
    20% {
        -webkit-transform: translateY(5px);
                transform: translateY(5px);
    }
    30% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
    100% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
}

@keyframes move-down {
    0% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
    10% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
    20% {
        -webkit-transform: translateY(5px);
                transform: translateY(5px);
    }
    30% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
    100% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
}

@-webkit-keyframes move-up {
    0% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
    10% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
    20% {
        -webkit-transform: translateY(-5px);
                transform: translateY(-5px);
    }
    30% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
    100% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
}

@keyframes move-up {
    0% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
    10% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
    20% {
        -webkit-transform: translateY(-5px);
                transform: translateY(-5px);
    }
    30% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
    100% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
}

.static-medicine {
    margin-bottom: 16px;
    padding: 4px;
    border-radius: 20px;
}

.static-medicine.row {
    margin-left: inherit;
    margin-right: inherit;
    padding-left: 16px;
    padding-right: 16px;
    line-height: 36px;
}

.static-medication-info-line > .main {
    display: inline;
}

.static-medication-info-line span:not(:first-child){
    border-left:1px solid darkslategrey;
    margin-left: 2px;
    padding: 0 4px 0 4px;
}

.static-medicine > .dosage {
    color: gray;
}

.static-medicine .dosage-text {
    color: var(--text-color);
    font-weight: bold;
}

.has-warning .form-control {
    border-color: #ff3333 !important;
}

.has-warning .form-control-feedback {
    color: #ff3333;
}

.has-warning .form-control:focus {
    border-color: #ff3333;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 6px #ff3333;
}


@media screen and (max-width: 786px) {
    .static-medicine > .renewable {
        text-align: left;
    }

    .static-medication-info-line > .secondary {
        display: block;
    }
}

.static-medicine > .renewable {
    text-align: right;
}

.static-medicine > div > .quantity {
    text-align: right;
}


.static-medicine .badge {
    background-color: #77ccae !important;
    font-size: 13px;
}


.bottom-line {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.bottom-line > *:nth-child(1) {
    flex: 1 0 auto;
    padding-right: 8px;
}

.bottom-line > *:nth-child(2) {
    flex: 0 1 auto;
}

.btn .caret {
    margin-left: 4px;
}
.no-margin-form-group {
    margin-bottom: 0;
}

.medicine-search-input {
    min-width: 250px;
}

.popped-list {
    margin-top: 0;
    position: absolute;
    z-index: 9999;
    width: 90%;
    overflow-y: scroll;
    max-height: 250px;
    box-shadow: 0 3px 5px 0 rgba(0,0,0,0.25);
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.grouped-item .popped-list {
    margin-top: 34px;
}

.admin-ways .popped-list {
    margin-top: -14px;
    background: white;
    width: calc(100% - 10px);
    margin-left: 5px;
}

.secondary {
    color: grey;
    font-size: 95%;
}

.secondary.bold {
    color: unset;
    font-weight: 900;
}

.inline-spacing div{
    margin: 0;
}

.btn-middle {
    border-radius: 0;
}

.btn-left {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.btn-right {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.text-input-static {
    padding-left: 6px;
    padding-right: 6px;
    display: inline-block;
    font-size: 12px;
    line-height: 1.5;
}

.dropdown-menu>li{
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: 400;
    line-height: 1.42857143;
    color: #333;
    white-space: nowrap;
}

.dropdown-menu>li:hover{
    background: #e8e8e8 repeat-x;
    background-image: -webkit-linear-gradient(top,#f5f5f5 0,#e8e8e8 100%);
    background-image: -webkit-gradient(linear,left top,left bottom,from(#f5f5f5),to(#e8e8e8));
    background-image: -webkit-gradient(linear,left top, left bottom,color-stop(0, #f5f5f5),to(#e8e8e8));
    background-image: linear-gradient(to bottom,#f5f5f5 0,#e8e8e8 100%);
    color: #262626;
    text-decoration: none;
    background-color: #f5f5f5;
    cursor: pointer;
}

/*.medication-info-line > .first-line {*/
/*    padding-top: 12px;*/
/*    line-height: 16px;*/
/*}*/

/*.medication-info-line .secondary {*/

/*}*/

/*.medication-info-line span{*/
/*    padding: 0 8px;*/
/*}*/

/*.medication-info-line span:not(:first-child){*/
/*    border-left:1px solid darkslategrey;*/
/*}*/

/*.medication .pull-right {*/
/*    text-align: right;*/
/*}*/

.medication {
    border: 1px solid var(--border-color);
    background: white;
    border-radius: 20px;
    margin-bottom: 4px;
    margin-left: 0;
    margin-right: 0;
}

.medication:hover {
    box-shadow: 2px 2px 5px 0 var(--box-shadow-color);
}

.medication.selected {
    box-shadow: 0 0 5px 0 rgba(0,0,0,0.6);
}

.medication .form-horizontal {
    line-height: normal;
}

.selectable:hover {
    background-color: lightblue;
    cursor: pointer;
}

.plus-minus-container {
    padding-left: 6px;
    padding-right: 6px;
}

.add-button{
    background: lightblue;
    line-height: 126px;
    min-width: 50px;
    height: 126px;
    text-align: center !important;
    font-weight: bolder;
    color: white;
}

.renewable-description {
    margin-right: 2px;
}

.renewable-description .btn {
    padding-left: 10px;
    padding-right: 10px;
}

.minus-button {
    background: var(--light-blue-button-background);
    color: white;
}

.minus-button[disabled]{
    background: var(--light-blue-button-color);
    opacity: 0.5;
}
.plus-button {
    background: var(--dark-blue-button-background);
    color: white;
}

.plus-button[disabled]{
    background: var(--dark-blue-button-color);
    opacity: 0.5;
}
.plus-button:hover, .minus-button:hover {
    color: gray;
}

.plus-button:focus, .minus-button:focus {
    outline: none;
}

.plus-button:active {
    background: #33ccff;
    box-shadow: none;
}

.minus-button:active {
    box-shadow: none;
    background: #b4ecff;
}

.medication-info-line span.justify{
    border-left:2px solid #ff4d4d !important;
    color: #ff4d4d;
}

.top-margin {
    margin-top: 8px;
}

fieldset > *:last-child{
    margin-bottom: 0;
}




@media (max-width: 767px) {
    .btn-xs-block {
        display: block;
        width: 100%;
    }
    input[type="submit"].btn-xs-block,
    input[type="reset"].btn-xs-block,
    input[type="button"].btn-xs-block {
        width: 100%;
    }
    .btn-block + .btn-xs-block,
    .btn-xs-block + .btn-block,
    .btn-xs-block + .btn-xs-block {
        margin-top: 0.5rem;
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    .btn-sm-block {
        display: block;
        width: 100%;
    }
    input[type="submit"].btn-sm-block,
    input[type="reset"].btn-sm-block,
    input[type="button"].btn-sm-block {
        width: 100%;
    }
    .btn-block + .btn-sm-block,
    .btn-sm-block + .btn-block,
    .btn-sm-block + .btn-sm-block {
        margin-top: 0.5rem;
    }
}
@media (min-width: 992px) and (max-width: 1199px) {
    .btn-md-block {
        display: block;
        width: 100%;
    }
    input[type="submit"].btn-md-block,
    input[type="reset"].btn-md-block,
    input[type="button"].btn-md-block {
        width: 100%;
    }
    .btn-block + .btn-md-block,
    .btn-md-block + .btn-block,
    .btn-md-block + .btn-md-block {
        margin-top: 0.5rem;
    }
}
@media (min-width: 1200px) {
    .btn-lg-block {
        display: block;
        width: 100%;
    }
    input[type="submit"].btn-lg-block,
    input[type="reset"].btn-lg-block,
    input[type="button"].btn-lg-block {
        width: 100%;
    }
    .btn-block + .btn-lg-block,
    .btn-lg-block + .btn-block,
    .btn-lg-block + .btn-lg-block {
        margin-top: 0.5rem;
    }
}



.first-line {
    padding: 6px 6px 0 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-items: center;
    justify-content: flex-start;
    justify-self: center;
}

.first-line .name {
    order: 0;
    flex: 1 1 auto;
    align-self: auto;
}

.first-line .buttons {
    order: 0;
    flex: 0 0 auto;
    align-self: auto;
}

.additional-content {
    padding: 0 6px 0 6px;
    margin-bottom: 6px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-items: center;
    justify-content: space-between;
    justify-self: center;
}

.additional-content .information {
    order: 0;
    flex: 1 1 auto;
    align-self: auto;
}

.additional-content .information span{
    display: inline;
    padding: 0 6px;
}

.additional-content .information span:not(:first-child){
    border-left:1px solid darkslategrey;
}

.additional-content .buttons {
    order: 0;
    flex: 0 0 auto;
    align-self: auto;
}


@media only screen and (max-width: 600px) {
    .additional-content .information span {
        border-left:1px solid darkslategrey;
    }
    .additional-content .information span{
        display: block;
    }
}

.custom-icon{
    width: 18px;
    height: 18px;
    margin-bottom: 3px;
}

.custom-icon.small {
    width: 13px;
    height: 13px;
}

.custom-icon.center-with-text {
    margin-bottom: 3px;
}
.badge .custom-icon {
    margin-bottom: 0;
}

/*TODO change this to actually be a color for icon*/
.custom-icon {
    -webkit-filter: invert(1);
            filter: invert(1);
}

.custom-icon.white {
    -webkit-filter: invert(0);
            filter: invert(0);
}

.clickable:hover {
    cursor: pointer;
}

.short-medicine-title-container > div > * {
    margin: auto 2px;
}

.simple-medicine-view .panel {
    margin-bottom: 5px;
    box-shadow: none;
}

.simple-medicine-view .panel .panel-heading {
    min-height: unset;
}

.simple-medicine-view .row {
    line-height: 28px;
}

.short-medicine-title-container > .badge{
    font-size: smaller;
}

.badge.icon.faded {
    opacity: 0.3;
}

.badge.icon , .badge.quantity {
    font-size: small;
}

.short-medicine-info .number-editing-line {
    margin-top: 8px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    justify-items: center;
    align-items: center;
}

.number-editing-line .flex-item {
    padding-left: 2px;
    padding-right: 2px;
    margin-right: 4px;
    margin-left: 4px;
}

.number-editing-line .flex-item:nth-child(1) {
    flex: 0 1 auto;
}

.number-editing-line .flex-item:nth-child(2) {
    flex: 0 1 auto;
}

.number-editing-line .flex-item:nth-child(3) {
    flex: 0 1 auto;
}

.number-editing-line .flex-item:nth-child(4) {
    flex: 1 0 auto;
}

.number-editing-line .flex-item:nth-child(5) {
    flex: 0 1 auto;
}

.short-medicine-info .dosage .dosage-text {
    padding: 0;
}

.short-medicine-info .dosage .form-group {
    margin-bottom: 0;
}

.short-medicine-info .btn-xs {
    min-width: 36px;
    padding: 5px;
}
.short-medicine-info .dosage > div > .content {
    font-size: smaller;
    margin-top: 6px;
    display: block;
    border-left: 2px solid grey;
    padding-left: 8px;
}


.medicine-view .panel-heading {
    padding-left: 0;
    padding-right: 0;
}

.medicine-view .panel-body {
    padding: 2px;
}


.btn-minus {
    background: #03c1af;
    color: white;
}

.btn-plus {
    background: #02766b;
    color: white;
}

.dosage-input {
    height: 30px;
}

.commercial-icon {
    margin-right: 4px;
    width: 18px;
    height: 18px;
}

.dosage-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.short-medicine-info .dosage .dosage-container .full-flex-inner .form-group {
    margin-bottom: 8px;
}
.dosage-container .flex-inner-item:nth-child(1) {
    flex: 1 1 auto;
}

.dosage-container .full-flex-inner {
    flex: 1 0 auto;

}

.dosage-container .flex-inner-item:nth-child(2) {
    margin-left: 18px;
    flex: 0 1 auto;
}

.basket {
    border: 1px solid #6fa9a9;
    border-radius: 5px;
}

.basket-list ul{
    margin-bottom: 0;
}

.badge {
    font-size: 16px;
    padding: 4px 8px;
    color: white;
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0, var(--badge-color)), to(var(--badge-color)));
    background: -webkit-linear-gradient(top, var(--badge-color) 0, var(--badge-color) 100%);
    background: linear-gradient(to bottom, var(--badge-color) 0, var(--badge-color) 100%);
}


.basket-panel > .panel > .panel-content > .panel-body {
    padding: 8px 6px;
}

/*Notification Bubble*/

.notification-bubble {
    position: absolute;
    top: -4px;
    right: -4px;
    padding: 6px;
    border-radius: 50%;
    background: #db282e;
    color: white;
}




/*Notification Bubble*/

.loading {
    margin: 20px;
    text-align: center;
}

.loading span {
    color: var(--secondary-color);
}

.favorite-modal .modal-body{
    background: rgba(255, 255, 205, 0.35);
}

.menu {
    width: 220px;
    float: left;
    position:fixed;
    /*background:var(--primary-color);*/
    background-image: -webkit-linear-gradient(top, #03c1af 0, #028578 100%);
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, #03c1af), to(#028578));
    background-image: linear-gradient(to bottom, #03c1af 0, #028578 100%);
    height: 100vh;
    min-width: 220px;
    outline: none;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.menu .expand-button {
    display: none;
}
.sidenav-container .content {
    padding-left: 230px;
    padding-right: 8px;
    padding-top: 8px;
    height: 100%;
}

.sidenav-container {
    height: 100%;
}

.custom-tooltip {
    display: none !important;
}

@media (min-width: 992px){
    .sidenav-container .container {
        width: calc(970px - 240px);
    }

}

@media (min-width: 768px){
    .sidenav-container .container {
        width: calc(100% - 70px);
    }
}

@media (min-width: 1200px){
    .sidenav-container .container {
        width: calc(1170px - 240px);
    }
}


.close-button {
    display: none;
}
.menu .patient-identification-box {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    background: rgba(0, 0, 0, 0.1);
    padding: 0.5em 0.5em;
    text-align: center;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.menu .patient-identification-box .photo {
    border-radius: 50%;
    overflow: hidden;
    border: 4px solid var(--primary-faded-color);
    box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.2);
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    order: 0;
    flex: 0 1 auto;
    align-self: auto;
    width: 68px;
    height: 68px;
    margin-right: 12px;
}

.menu .patient-identification-box .information {
    text-align: center;
    color: var(--text-white-color);
    order: 0;
    flex: 1 2 auto;
    align-self: center;
}

.menu .patient-identification-box .information .name{
    text-align: center;
    margin-top: 4px;
    font-size: 18px;
    color: var(--text-white-color);
}

.menu .patient-identification-box .information .gender.fa-venus, fa-mars {
    margin-right: 4px;
    background: white;
    padding: 1px 5px;
    border-radius: 5px;
    color: var(--primary-color)
}

.menu .patient-identification-box .information .gender.fa-mars {
    margin-right: 4px;
    background: white;
    padding: 1px 5px;
    border-radius: 5px;
    color: var(--primary-color)
}



.menu .patient-identification-box .information hr {
    margin: 10px;
}

.menu .patient-identification-box .information > div {
    color: var(--text-white-color);
}

.menu .patient-identification-box .information > div > .text-muted {
    color: var(--text-white-color);
    opacity: 0.8;
}
.menu ul {
    list-style: none;
    padding: 0.5em 0;
    margin: 0;
}
.menu .nav-item > a {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 8px 12px;
    -webkit-transition: all 0.15s linear;
    transition: all 0.15s linear;
    cursor: pointer;
}

.menu .nav-item > a.active > * {
    color: var(--text-selected-color) !important;
}

.menu .nav-item > a.active {
    background: var(--selection-background-color);
    margin-left: 2px;
    border-radius: 20px 0 0 20px;
}

.menu .nav-item.inner-item > a {
    padding-left: 26px;
}

.menu .nav-item.inner-item > a.active > * {
    color: var(--text-selected-color);
}

.menu .nav-item.disabled > a {
    pointer-events: none;
}

.menu .nav-item.disabled > a > * {
    opacity: 0.4;
}

.menu .nav-item > a:focus {
    text-decoration: none !important;
}

.menu .nav-item.disabled:hover {
    background-color: inherit;
    cursor: auto;
}

.menu .nav-item.disabled:hover > a {
    cursor: not-allowed;
}
.menu .nav-item > a > span {
    flex: 0 1;
    margin-right: 0;
    min-width: 28px;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    float: left;
    color: var(--text-white-color);
}

.menu .nav-item > a > .text {
    flex: 1 1;
    color: var(--text-white-color);
}

.menu .nav-item > a > .text .badge {
    float: right;
    background: var(--text-white-color);
    color: var(--primary-color);
    padding-top: 2px;
    padding-bottom: 2px;
    font-size: small;
}

.menu .nav-item > a > .text > .caret-container {
    float: right;
}

.menu .nav-item > a > .text > .caret-container > span{
    color: var(--text-white-color);
}

.menu .nav-item.inner-item > a > .text .badge{
    padding-top: 2px;
    margin-top: 2px;
}

.menu .nav-item > a.active > .text .badge {
    background: var(--primary-color);
    color: var(--text-white-color);
}

.menu .nav-item:hover {
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 20px 0 0 20px;
}
.menu .nav-item:focus {
    outline: none;
}

.smartphone-menu-trigger {
    display: none;
}

@media screen and (max-width: 992px) and (min-width: 400px) {
    .sidenav-container {
        display: block;
    }

    .custom-tooltip {
        display: block !important;
    }
    .sidenav-container .content {
        padding-top: 16px;
        padding-left: 76px;
        padding-right: 6px;
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
    }
    .menu {
        min-width: 70px;
        position: absolute;
        width: 70px;
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        z-index: 1000;
    }

    .menu .expand-button {
        text-align: center;
        color: var(--text-white-color);
        display: block;
        width: 100%;
        height: 40px;
        position: absolute;
        bottom: 5px;
        font-size: 2rem;
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
    }

    .menu .patient-identification-box {
        flex-direction: column;
        justify-content: center;
        justify-items: center;
        align-content: center;
    }

    .menu .patient-identification-box .photo {
        width: 50px;
        height: 50px;
        margin: 0;
    }

    .menu .patient-identification-box .information {
        display: none;
        opacity: 0;
    }

    .menu.open {
        z-index: 1001;
        width: 240px;
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
    }

    .menu.open .patient-identification-box {
        flex-direction: row;
        justify-content: center;
        justify-items: center;
        align-content: center;
    }

    .menu.open .patient-identification-box .information {
        display: block;
        opacity: 1;
    }

    .menu.open .patient-identification-box .photo {
        margin-right: 12px;
    }

    .menu .nav-item > a {
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        display: block;
        height: 36px;
        margin-bottom: 10px;
        padding: 8px;
        position: relative;
    }

    .menu .nav-item.with-inner {
        color: red !important;
    }

    .menu.open .nav-item > a {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        align-content: center;
        padding: 8px 16px 8px 8px;
        -webkit-transition: all 0.15s linear;
        transition: all 0.15s linear;
        cursor: pointer;
    }

    .menu.open .nav-item > a > span {
        flex: 0 1;
        margin-right: 8px;
        min-width: 28px;
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        float: left;
        color: var(--text-white-color);
    }

    .menu.open .nav-item > a > .text {
        flex: 1 1;
        color: var(--text-white-color);
        display: block;
    }

    .menu .nav-item > a >span {
        width:100%;
        text-align: center;
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        flex: 1 1;
        margin-right: 0;
        font-size: 2rem;
        opacity: 1;
    }

    .menu .nav-item.inner-item {
        margin-left: 0;
    }
    .menu .nav-item > a > .text {
        display: none;
        flex: 0 1;
    }
}
@media screen and (max-width: 400px) {
    .close-button {
        position: absolute;
        display: block;
        font-size: 3rem;
        right: 5px;
        color: whitesmoke;
        opacity: 0.7;
    }

    .sidenav-container {
        padding-top: 36px;
    }

    .custom-tooltip {
        display: none !important;
    }

    .sidenav-container {
        position: relative;
        display: block;
    }
    .sidenav-container .content {
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        padding-left: 8px;
        padding-right: 8px;
    }
    .menu {
        flex: none;
        position: fixed;
        z-index: 101;
        overflow-y: scroll;
        width: 260px;
        top: 0;
        box-shadow: 0 0 0 100em rgba(0, 0, 0, 0);
        -webkit-transform: translate3d(-260px, 0, 0);
                transform: translate3d(-260px, 0, 0);
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
    }
    .smartphone-menu-trigger {
        width: 40px;
        top: 48px;
        position: fixed;
        height: 40px;
        display: flex;
        z-index: 100;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        align-content: center;
    }
    .smartphone-menu-trigger span {
        flex: 1 1;
        text-align: center;
        font-size: 2.5rem;
        color: grey;
    }
    .menu .nav-item > a {
        padding: 1em 1em 1em 1em;
        font-size: 1.2em;
    }
    .menu.open {
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
        box-shadow: 0 0 0 100em rgba(0, 0, 0, 0.6);
    }
    .menu.open .smartphone-menu-trigger {
        pointer-events: none;
    }
}

.patient-history-container .receipt-viewer {
    padding-left: 8px;
}

.select-receipt {
    border: 1px solid var(--border-color);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    flex: 1 1;
    align-items: center;
    justify-content: center;
}


.history-panel .panel-body {
    padding-left: 0;
    padding-right: 0;
}

.patient-select {
    width: auto;
}

.header {
    line-height: 42px;
}

.medicine-list-line {
    padding-bottom: 0;
}

.medicine-list-line .static-medicine {
    margin-bottom: 0;
}

@media screen and (max-width: 768px) {
    .footer.pull-right {
        margin-top: 10px;
        float:none !important
    }
}

.receipt-item {
    padding: 8px 8px 6px 8px;
}

.receipt-item li {
    list-style: none;
}

.receipt-list .list-group-item:nth-child(odd) {
    background-color: white;
}

.receipt-list {
    border-radius: 20px;
    background: var(--selection-background-color);
    padding: 0;
}

.monthly-receipt-list .month {
    padding-left: 16px;
    font-size: 18px;
    background: var(--background-color);
    box-shadow: none;
}

.receipt-list > .list-group-item:first-child {
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
}

.receipt-list > .list-group-item:last-child {
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
}

.receipt-list .list-group-item {
    background: none;
    margin: 0;
    padding: 0;
}

/*.receipt-list > :first-child > a > * {*/
/*    border-top-right-radius: 20px;*/
/*    border-top-left-radius: 20px;*/
/*}*/

/*.receipt-list > :last-child > a > * {*/
/*    border-bottom-right-radius: 20px;*/
/*    border-bottom-left-radius: 20px;*/
/*}*/

.receipt-list .list-group-item > a {
    color: var(--text-color);
}

.receipt-list .list-group-item > a:hover {
    background: white;
}

.receipt-list .list-group-item > a > div {
    padding: 8px 4px 6px 8px
}

.receipt-list .list-group-item > a:focus {
    text-decoration: none;
}

.receipt-list .list-group-item > a.active > * {
    background: white;
}
.receipt-list > div:hover > * {
    color: var(--text-color);
    text-decoration: none;
}


.list-group-item > a > .receipt-item {
    background: var(--selection-background-color);
}

.receipt-item .drugs {
    margin-top: 4px;
    margin-bottom: 2px;
}

.receipt-item .drugs > li:not(:first-child) {
    border-left:1px solid darkslategrey;
}
.receipt-item .drugs > li {
    float: left;
    padding-left: 2px;
    padding-right: 2px;
}

.receipt-item .drugs li {
    font-size: smaller;
}

.doctor-profile {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: center;
    align-items: stretch;
}

.doctor-profile .id-container {
    background: whitesmoke;
    border: 1px solid var(--border-color-light);
    margin: 0  auto 6px auto;
    border-radius: 20px;
    width: 100%;
    order: 0;
    flex: 0 1 auto;
    align-self: auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
}

.doctor-profile .id-container .photo {
    order: 0;
    flex: 0 0 auto;
    align-self: auto;
    height: 120px;
    width: 120px;
    position: relative;


    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 6px;
    text-align: center;
}

.doctor-profile .id-container .photo .photo-edit-container {
    position: absolute;
    top: 14px;
    right: 14px;
    padding: 4px 4px 6px 8px;
    border-radius: 0 16px 0 20px;
    background: rgba(0,0,0, 0.1);
}

.doctor-profile .id-container .photo .photo-edit-container:hover {
    background: rgba(0,0,0, 0.25);
}

.doctor-profile .id-container .photo .profile-photo {
    width: 100px;
    height: 100px;
    border-radius: 20px;
    overflow: hidden;
    border: 4px solid var(--primary-color);
    box-shadow: 0 0 0 4px var(--primary-faded-color);
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    order: 0;
    flex: 0 1 auto;
    align-self: auto;
}

.doctor-profile .id-container .information {
    order: 0;
    flex: 1 1 auto;
    align-self: auto;
    padding-right: 6px;
}

.doctor-profile .id-container .information .name {
    font-size: x-large;
}

.doctor-profile .id-container .information .number {
    cursor: default;
    font-size: unset;
    text-align: center;
}

.doctor-profile .id-container .information .secondary-info {
    margin-top: 10px;
    margin-bottom: 10px;
}

.doctor-profile .id-container .information .secondary-info > * {
    margin-top: 10px;
    margin-bottom: 10px;
}

.doctor-profile .id-container .information h3 {
    margin-top: 0;
}

.doctor-profile .id-container .information > div, h3 {
    margin-left: 1px;
}


.doctor-profile .data-container {
    background: whitesmoke;
    border: 1px solid var(--border-color-light);
    margin: 0  auto 6px auto;
    border-radius: 20px;
    width: 100%;
    order: 0;
    flex: 0 1 auto;
    align-self: auto;
    padding: 20px;
}

.horizontal-buttons-container > * {
    margin-left: 2px;
}

.billing-form  .input-group-addon {
    min-width: 50px;
}

/*.billing-form .first-right-child {*/
/*    border-top-right-radius: 20px;*/
/*}*/

/*.billing-form .first-left-child {*/
/*    border-top-left-radius: 20px;*/
/*}*/
/*.billing-form .last-right-child {*/
/*    border-bottom-right-radius: 20px;*/
/*}*/
/*.billing-form .last-left-child {*/
/*    border-bottom-left-radius: 20px;*/
/*}*/

/*.billing-form .input-group > input {*/
/*    background: none;*/
/*    border-top: none;*/
/*    border-left: none;*/
/*    border-right: none;*/
/*    box-shadow: none;*/
/*    border-bottom: 1px solid grey;*/
/*}*/

/*.billing-form .input-group {*/
/*    margin-left: 8px;*/
/*}*/


/*.billing-form .input-group-addon {*/
/*    background: none;*/
/*    border-top: none;*/
/*    border-left: none;*/
/*    border-right: none;*/
/*    box-shadow: none;*/
/*    border-bottom: 1px solid grey;*/
/*    border-bottom-right-radius: 0;*/
/*    border-bottom-left-radius: 0;*/
/*}*/

/*.billing-address-form .form-horizontal .form-group {*/
/*    margin-left: 0;*/
/*    margin-right: 0;*/
/*}*/

.cards-list {
    margin-bottom: 0;
    box-shadow: none;
}

.card-line {
    padding: 0;
    border: 0;
    box-shadow: none;
    outline: none;
    display: flex;
    flex-direction: row;
    justify-items: flex-start;
    align-items: flex-start;
    align-content: flex-start;
}

.card-line .card-type {
    padding-top: 1px;
    flex: 0 1 auto;
    margin-right: 8px;
}

.card-line .card-number {
    padding-top: 2px;
    line-height: 28px;
    flex: 1 0 auto;
}

.card-line .action-buttons {
    padding-top: 4px;
    flex: 1 1 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-content: center;
    align-items: center;
}

.card-line .action-buttons > * {
    order: 0;
    flex: 0 1 auto;
    align-self: auto;
    margin-left: 4px;
}

.fiscal-form .inner-left-column {
    padding-left: 0;
}

.fiscal-form .inner-right-column {
    padding-right: 0;
}


.patient-history-container .receipt-viewer {
    padding-left: 8px;
}

.select-receipt {
    border: 1px solid var(--border-color);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    flex: 1 1;
    align-items: center;
    justify-content: center;
}


.history-panel .panel-body {
    padding-left: 0;
    padding-right: 0;
}


/*.patient-history-container .receipt-viewer {*/
/*    display: flex;*/
/*    flex-wrap: nowrap;*/
/*    flex-direction: row;*/
/*    justify-content: flex-start;*/
/*    align-content: center;*/
/*    align-items: stretch;*/
/*}*/

/*.patient-history-container .receipt-viewer .history-panel.full {*/
/*    flex: 2 0 auto;*/
/*    margin-right: 6px;*/
/*    transition: 200ms;*/
/*    align-self: auto;*/
/*}*/


/*.patient-history-container .receipt-viewer .history-panel.fitted {*/
/*    transition: 200ms;*/
/*    max-width: 100%;*/
/*    flex: 0 1 auto;*/
/*    align-self: auto;*/
/*}*/

/*.patient-history-container .receipt-viewer .receipt {*/
/*    flex: 9 0 auto;*/
/*    align-self: auto;*/
/*    margin-left: 6px;*/
/*}*/

.receipt-item {
    padding: 8px 8px 8px 16px;
}

.receipt-item li {
    list-style: none;
}

/*.receipt-list > *:nth-child(odd) {*/
/*background-color: whitesmoke;*/
/*}*/

.receipt-list {
    border-radius: 20px;
    background: var(--selection-background-color);
    padding: 0;
}

.monthly-receipt-list .month {
    padding-left: 16px;
    font-size: 18px;
    background: var(--background-color);
    box-shadow: none;
}

.receipt-list > .list-group-item:first-child {
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
}

.receipt-list > .list-group-item:last-child {
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
}

.receipt-list .list-group-item {
    background: none;
    margin: 0;
    padding: 0;
}

/*.receipt-list > :first-child > a > * {*/
/*    border-top-right-radius: 20px;*/
/*    border-top-left-radius: 20px;*/
/*}*/

/*.receipt-list > :last-child > a > * {*/
/*    border-bottom-right-radius: 20px;*/
/*    border-bottom-left-radius: 20px;*/
/*}*/

.receipt-list .list-group-item > a {
    color: var(--text-color);
}

.receipt-list .list-group-item > a:hover {
    background: white;
}

.receipt-list .list-group-item > a > div {
    padding: 8px 4px 6px 8px
}

.receipt-list .list-group-item > a:focus {
    text-decoration: none;
}

.receipt-list .list-group-item > a.active > * {
    background: white;
}
.receipt-list > div:hover > * {
    color: var(--text-color);
    text-decoration: none;
}


.list-group-item > a > .receipt-item {
    background: var(--selection-background-color);
}

.receipt-item .drugs {
    margin-top: 4px;
    margin-bottom: 2px;
}


.receipt-item .drugs > li:first-child {
    margin-left: 0;
}
.receipt-item .drugs > li {
    float: left;
    padding-left: 5px;
    padding-right: 5px;
    margin-left: 2px;
    margin-right: 2px;
    border: 1px solid darkslategrey;
    border-radius: 20px;
}

.receipt-item .drugs li {
    font-size: smaller;
}

.prescribe-container {
    min-height: 320px;
}

.prescribe-container .modal-body {
    background: var(--background-color);
}
.prescribe-container .basket-float-btn {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
    padding-right: 0;
}

.prescribe-modal {
    height: 100%;
}

.prescribe-modal .modal-content {
    height: 100%;
    background: #e6f1ee;
}

.prescribe-modal .modal-body {
    max-height: calc(100% - 56px);
}

.prescribe-modal-header {
    border-bottom-color: var(--border-color);
}

.row-fluid {
    margin-right: -15px;
    margin-left: -15px;
}

.row-fluid div {
    -webkit-transition: width 0.1s ease, height 0.1s ease, margin 0.1s ease, padding 0.1s ease, opacity 0.1s ease;
    transition: width 0.1s ease, height 0.1s ease, margin 0.1s ease, padding 0.1s ease, opacity 0.1s ease;
}

.favorites-panel .panel-body {
    padding: 0;
}

.favorites-panel .accordion > *:first-child {
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
}

.favorites-panel .accordion__body {
    padding: 0
}

.favorites-panel .panel-body .accordion {
    background: transparent;
    border: none;
    border-radius: 0;
}

.prescribe-container .loading {
    margin: 20px;
    text-align: center;
}

.prescribe-container .loading span {
    color: var(--secondary-color);
    /*animation: rotating 2s linear infinite;*/
}

.yellow-icon {
    color: #ffd500;
}

.buttons-container > *{
    margin-left: 2px;
    margin-right: 2px;
}

@media (max-width: 768px) {
    .prescribe-container > div{
        padding-right: 4px;
    }
}


@-webkit-keyframes rotating /* Safari and Chrome */ {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes rotating {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

.confirmation-container > div {
    padding: 8px;
}

.receipt-signer-modal .modal-body {
    background:var(--background-color);
}



/*.receipt-signer-window .accordion > *:first-child {*/
/*    border-top-left-radius: 20px;*/
/*    border-top-right-radius: 20px;*/
/*}*/


/*.receipt-signer-window .accordion > *:first-child > .accordion__heading .accordion__button {*/
/*    border-top-left-radius: 20px;*/
/*    border-top-right-radius: 20px;*/
/*}*/

/*.receipt-signer-window .accordion > *:first-child > .accordion__heading {*/
/*    border-top-left-radius: 20px;*/
/*    border-top-right-radius: 20px;*/
/*}*/

/*.receipt-signer-window .accordion .accordion__panel > div {*/
/*    background-color: red;*/
/*}*/


/*.receipt-signer-window .accordion__body {*/
/*    border: 1px solid whitesmoke;*/
/*    padding: 8px 16px;*/
/*}*/

/*.receipt-signer-window .accordion > .accordion__panel:nth-child(even) {*/
/*    background: red;*/
/*}*/

.sign-box {
    border: 1px solid #cccccc;
    border-radius: 20px;
    display: flex;
    padding-bottom: 10px;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.sign-box > div {

    width: 300px;
    /*flex: 1 0 auto;*/
}


.item-line {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-content: center;
    align-items: center;
}

.item-line > div:first-child {
    order: 0;
    flex: 1 0 auto;
    align-self: auto;
}

.item-line > div:last-child {
    order: 0;
    flex: 0 0 auto;
    align-self: auto;
}

.medicine-list {
    box-shadow: none;
}

.medicine-list > .list-group-item {
    border-radius: 20px;
}

.medicine-list > .list-group-item:nth-child(odd) {
    background: whitesmoke;
}


.receipt-line-title {
    display: flex;
    flex-direction: row;
}

.receipt-line-title > *:nth-child(1) {
    flex: 1 0 auto;
    font-weight: bold;
}

.receipt-line-title > *:nth-child(2) {
    position: absolute;
    right: 24px;
}

.signer > * {
    font-size: larger;
}

.medicine-line {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    align-content: center;
}

.medicine-line-item:nth-child(1) {
    order: 0;
    flex: 1 1 auto;
    align-self: auto;
    margin-right: 4px;
}

.medicine-line-item:nth-child(2) {
    order: 0;
    flex: 0 0 auto;
    align-self: stretch;
    text-align: right;
}

.medicines-container {
    min-height: 110px;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: center;
    align-content: center;
}

.medicine-line {
    padding: 4px 6px;
}

.medicines-list .medicine-line {
    border-radius: 20px;
}

.margin-top {
    margin-top: 8px
}

.no-side-padding {
    padding-left: 0;
    padding-right: 0;
}
.receipt-separator {
    margin-top: 4px;
    margin-bottom: 4px;
}

.medicines-list {
    margin-bottom: 0;
}

.no-margin-hr {
    margin-top: 0;
    margin-bottom: 0;
}

.medicines-container {
    min-height: 112px;
}

.medicines-container .list-group-item:first-child {
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
}

.medicines-container .list-group-item:last-child {
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
}

.medicines-container .list-group-item {
    padding-left: 16px;
    padding-right: 16px;
    border-radius: 0;
}

.signer > div > div:last-child {
    padding-top: 5px;
}


.medicines-list {
    box-shadow: none;
}

.confirmation-message {
    padding-top: 4px;
}

.guide-modal .modal-body{
    min-height: 300px;
    background: var(--background-color);
}

.dropdown.align-left .dropdown-menu{
    left: 0;
    right: unset;
}

.dropdown.align-right .dropdown-menu{
    right: 0;
    left: unset;
}

.dropdown .dropdown-menu>li>button{
    display: block;
    clear: both;
    font-weight: 400;
    line-height: 1.42857143;
    color: #333;
    white-space: nowrap;
    border: none;
    background: none;
}

.button-inline-form {
    margin-top: 24px;
}

.guide-entry-line .periods {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
}

.guide-entry-line .periods .title {
    line-height: 38px;
    margin-right: 4px;
}

.guide-entry-line .periods .period-item {
    background-color: var(--badge-color);
    color: white;
    font-weight: bold;
    border-radius: 20px;
    padding: 0 10px;
    line-height: 30px;
    margin: 4px 4px 4px 0;
}

.guide-entry-line .units {
    background: var(--secondary-color);
    color: white;
    border-radius: 20px;
    padding: 0 6px;
    line-height: 30px;
    margin: 4px 8px 4px 0;
}

.guides-list {
    border-radius: 20px;
}

.guides-list .guide-item {
    border-radius: 20px;
    margin-bottom: 8px;
}


.receipt-choice-list > a {
    color: unset;
}

.receipt-choice-list {
    box-shadow: none;
    border-radius: 20px;
}

.receipt-choice-list .receipt-item {
    border-radius: 20px;
    margin-bottom: 8px;
}

.line {
    font-size: x-large;
    line-height: 38px;
    margin-left: 8px;
    margin-right: 8px;
}


.inline-form-group-checkbox {
    margin-top: 22px;
}
.input-group-addon.btn-left {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}

.input-group-addon.btn-right {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
}


.guide-panel .panel-body {
    padding: 10px 0 10px 0;
}

.guide-panel .panel-heading .row{
    line-height: 30px;
}

.guide-panel .form-horizontal .form-group {
    margin-left: 0;
    margin-right: 0;
}

.frequence-select {
    max-width: 260px;
}

.frequence-select select {
    min-width: 30px;
}

.frequence-select .input-group-addon {
    padding-left: 4px;
    padding-right: 4px;
}

.frequence-select .input-group-addon span {
    max-width: 165px;
    text-overflow: ellipsis;
    display: block;
    white-space: nowrap;
    overflow: hidden;
}

.inline-form-group-checkbox {
    margin-top: 20px;
}

.input-select-group {
    display: inline;
}

.input-select-group .form-control:nth-child(1) {
    width: 35%;
}

.input-select-group .form-control:nth-child(2) {
    width: 65%;
}

.checkbox-inline+.checkbox-inline {
    margin-left: 0;
}

@media screen and (max-width: 768px)  {
    .frequence-select .input-group-addon {
        max-width: 155px;
    }
    .frequence-addon {
        max-width: 200px;
    }
}


.receipt-guides-panel-container .panel-body {
    padding: 0
}

.receipt-guides-panel-container .receipt-selector {
    width: 100%;
    padding: 0;
}

.receipt-guides-panel-container .receipt-selector .receipt-list {
    margin-top: 0;
    margin-bottom: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.receipt-guides-panel-container .receipt-selector .receipt-list > .list-group-item:last-child {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}
.receipt-guides-panel-container .receipt-selector .receipt-list > .list-group-item {
    border-radius: 0;
}


.guide-title > *:nth-child(1) {

}

.guide-title > *:nth-child(2) {
    padding-left: 16px;

}


.medicine-item:nth-child(odd) {
    background: rgba(198, 222, 215, 0.5);
}

.medicine-item:nth-child(even) {
    background: var(--selection-background-color);
}

.medicine-item {
    border-radius: 20px;
    margin-bottom: 8px;
    padding: 12px;
}

.medicine-item .name {
    font-size: 110%;
}


.visible-xxs {
    display: none;
}

.allergies-insertion .modal-body {
    padding: 0;
}

.allergies-insertion .modal-content .medication-insertion {
    background-color: #EFBBA2;
}

.allergies-insertion .modal-content .medication-insertion .modal-body {
    padding: 15px;
}

.allergies-insertion .modal-content .other-insertion .modal-body {
    padding: 15px;
}
.allergies-insertion .modal-content .other-insertion {
    background-color: #efe2a2;
}

.allergies-insertion .modal-content .no-bg {
    padding: 15px;
    background-color: white;
}

.allergies-insertion .modal-header {
    border-bottom-color: var(--border-color-light);
}

@media (max-width: 400px) {
    .hidden-xxs {
        display: none;
    }

    .visible-xxs {
        display: block;
    }
}

.buttons-container > * {
    margin-right: 4px;
    margin-bottom: 8px;
}

.buttons-container {
    text-align: right;
}

.dropdown-menu .dropdrown-group-title {
    text-transform: uppercase;
    color: #999999;
    font-size: 11px;
    font-weight: bold;
}

.dropdown-menu {
    border-radius: 20px;
    padding-top: 0;
    padding-bottom: 0;
}

.dropdown-menu > li:first-child {
    padding-top: 5px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}


.dropdown-menu > li:last-child {
    padding-bottom: 5px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}

.dropdown-menu > li.divider {
    padding: 0;
}

.dropdown-menu >li.dropdrown-group-title:hover {
    background: white !important;
    color: #999999;
    cursor: default !important;
}

.register{
    background: -webkit-linear-gradient(left, var(--secondary-color), var(--primary-color));
    padding: 3%;
    border-radius: 20px;
}
.register-left{
    text-align: center;
    color: #fff;
    margin-top: 4%;
}

.register-left .container-left {
    margin-left: -20%;
}
.register-left h1 {
    margin-top: 0;
}
.register-left .btn{
    margin-top: 30%;
    margin-bottom: 3%;
}
.register-right{
    background: #f8f9fa;
    border-radius: 20px;
}
.register-left img{
    margin-top: 15%;
    margin-bottom: 5%;
    width: 50%;
    -webkit-animation: rotating 10s linear infinite;
    animation: rotating 10s linear infinite;
}

.register-right .radio-form-group .form-control{
    background: #f8f9fa;
}

@-webkit-keyframes rotating /* Safari and Chrome */ {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes rotating {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.register .register-form{
    padding: 0 20px 20px;
}
.register-heading{
    text-align: center;
    color: #495057;
}

@media screen and (max-width: 769px) {
    .register {
        margin-top: -50px;
        border-radius: 0;
    }
    .register-left .btn{
        display: none;
    }

    .register-left .container-left {
        margin-left: unset;
    }
    .register-left img {
        margin-bottom: 5px;
        margin-top: 0;
    }
    .register-heading {
        padding-top: 10px;
    }
}


.input-group-addon.select-addon {
    padding: 0;
}

