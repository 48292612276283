.main-container {
    height: calc(100% - (52px + 16px + 26px + 20px + 20px));
    padding-left: 16px !important;
    padding-right: 16px !important;
    /*margin-bottom:50px;*/
}

@media screen and (max-width: 768px) {
    .main-container {
        padding-left: 2px !important;
        padding-right: 2px !important;
    }
}


@media (max-width: 767px) {

    .no-side-padding-for-container {
        padding: 0;
    }
}



/**
* ----------------------------------------------
* Demo styles
* ----------------------------------------------
**/
.accordion {
    border-radius: 20px;
}

.accordion__item + .accordion__item {
    border-radius: 20px;
}

.accordion__item .accordion__button {
    border-radius: 0;
}

.accordion > .accordion__item:first-child .accordion__button {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

.accordion > .accordion__item:last-child .accordion__button {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}

.accordion > .accordion__item:nth-child(odd) .accordion__button {
    background: white;
}

.accordion > .accordion__item:nth-child(even) .accordion__button {
    background: whitesmoke;
}

.accordion > .accordion__item:nth-child(odd) .accordion__panel {
    background: white;
}

.accordion > .accordion__item:nth-child(even) .accordion__panel {
    background: whitesmoke;
}

.accordion > .accordion__item:last-child .accordion__panel {
    padding-top: 16px;
    margin-top: -16px;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
}

.accordion__button {
    background-color: #f4f4f4;
    color: #444;
    cursor: pointer;
    padding: 10px;
    width: 100%;
    text-align: left;
    border: none;
}

.accordion__button:hover {
    background-color: #ddd;
}

.accordion__panel {
    padding: 10px 20px;
}
