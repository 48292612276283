.user-container {
    overflow-y: hidden;
    background: whitesmoke;
    padding: 0 6px 6px 6px;
    height: 100%;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
}

.user-container .id-container {
    border: 1px solid var(--border-color-light);
    margin: 0  auto 6px auto;
    border-radius: 20px;
    width: 100%;
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
    -webkit-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    align-self: auto;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.user-container .id-container .photo {
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    align-self: auto;
    height: 120px;
    width: 120px;

    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 6px;
    text-align: center;
}

.user-container .id-container .photo .profile-photo {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
    border: 4px solid var(--primary-color);
    box-shadow: 0 0 0 4px var(--primary-faded-color);
    transition: all 0.3s ease-in-out;
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
    -webkit-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    align-self: auto;
}

.user-container .id-container .information {
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    align-self: auto;
    padding-right: 6px;
}

.user-container .id-container .information div.disabled {
    cursor: default;
}

.user-container .id-container .information .btn{
    margin-left: 1px;
    margin-right: 1px;
}

.user-container .id-container .information .name {
    font-size: larger;
}

.user-container .id-container .information .gender.fa-venus {
    margin-right: 4px;
    color: hotpink;
}


.user-container .id-container .information .gender.fa-mars {
    margin-right: 4px;
    color: dodgerblue;
}

.user-container .id-container .information h3 {
    margin-top: 0;
}

.user-container .id-container .information > div, h3 {
    margin-left: 1px;
}

.user-container .patient-actions {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-content: center;
    align-items: center;
}

.user-container .clinical-data-container {
    margin: 6px  auto;
    width: 100%;
    border: 1px solid var(--border-color-light);
    border-radius: 20px;
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
    -webkit-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    align-self: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.user-container .clinical-data-container .icon {
    margin: auto 50px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 6px;
    text-align: center;
}

.user-container .clinical-data-container .icon > span {
    width: 50px;
    height: 50px;
    color: var(--light-blue);
    padding-top: 5px;
    font-size: 3rem;
    border-radius: 50%;
    overflow: hidden;
    border: 4px solid var(--light-blue);
    box-shadow: 0 0 0 2px var(--box-shadow-color);
    transition: all 0.3s ease-in-out;
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
    -webkit-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    align-self: auto;
}

.user-container .events-container {
    overflow-y: scroll;
    order: 0;
    flex: 1 1 auto;
    align-self: auto;
}

.user-container .events-container .events {
    height: 100%;
}

.user-container .actions-container {
    border: 1px solid var(--border-color-light);
    border-radius: 20px;
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
    -webkit-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    align-self: auto;
}

.user-container .actions-container .buttons {
    margin: 30px auto;
    width: 100%;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: space-around;
    -ms-flex-pack: justify;
    justify-content: space-around;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.user-container .actions-container .buttons .btn {
    padding-left: 24px;
    padding-right: 24px;
}

.user-container .clinical-data-container {
    padding: 8px;
}

.user-container .clinical-data-container .icon.with-allergy > span {
    color: var(--attention-color);
    border-color: var(--attention-color);
}

.footer-checkbox-container .form-group{
    margin-bottom: 0;
}

.footer-checkbox-container .form-group .checkbox{
    text-align: left;
    margin-bottom: 0;
    margin-top: 0;
}

@media (max-width: 768px) {
    .user-container .clinical-data-container .icon {
        margin: auto 5%;
    }
    .user-container .actions-container .buttons {
        flex-wrap: wrap;
    }

    .user-container .actions-container .buttons .btn {
        padding-left: 36px;
        padding-right: 36px;
    }


    .user-container .actions-container .buttons > * {
        margin-top: 5px;
        margin-bottom: 5px;
    }
}
