.clickable:hover {
    cursor: pointer;
}

.short-medicine-title-container > div > * {
    margin: auto 2px;
}

.simple-medicine-view .panel {
    margin-bottom: 5px;
    box-shadow: none;
}

.simple-medicine-view .panel .panel-heading {
    min-height: unset;
}

.simple-medicine-view .row {
    line-height: 28px;
}

.short-medicine-title-container > .badge{
    font-size: smaller;
}

.badge.icon.faded {
    opacity: 0.3;
}

.badge.icon , .badge.quantity {
    font-size: small;
}

.short-medicine-info .number-editing-line {
    margin-top: 8px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    justify-items: center;
    align-items: center;
}

.number-editing-line .flex-item {
    padding-left: 2px;
    padding-right: 2px;
    margin-right: 4px;
    margin-left: 4px;
}

.number-editing-line .flex-item:nth-child(1) {
    flex: 0 1 auto;
}

.number-editing-line .flex-item:nth-child(2) {
    flex: 0 1 auto;
}

.number-editing-line .flex-item:nth-child(3) {
    flex: 0 1 auto;
}

.number-editing-line .flex-item:nth-child(4) {
    flex: 1 0 auto;
}

.number-editing-line .flex-item:nth-child(5) {
    flex: 0 1 auto;
}

.short-medicine-info .dosage .dosage-text {
    padding: 0;
}

.short-medicine-info .dosage .form-group {
    margin-bottom: 0;
}

.short-medicine-info .btn-xs {
    min-width: 36px;
    padding: 5px;
}
.short-medicine-info .dosage > div > .content {
    font-size: smaller;
    margin-top: 6px;
    display: block;
    border-left: 2px solid grey;
    padding-left: 8px;
}


.medicine-view .panel-heading {
    padding-left: 0;
    padding-right: 0;
}

.medicine-view .panel-body {
    padding: 2px;
}


.btn-minus {
    background: #03c1af;
    color: white;
}

.btn-plus {
    background: #02766b;
    color: white;
}

.dosage-input {
    height: 30px;
}

.commercial-icon {
    margin-right: 4px;
    width: 18px;
    height: 18px;
}

.dosage-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.short-medicine-info .dosage .dosage-container .full-flex-inner .form-group {
    margin-bottom: 8px;
}
.dosage-container .flex-inner-item:nth-child(1) {
    flex: 1 1 auto;
}

.dosage-container .full-flex-inner {
    flex: 1 0 auto;

}

.dosage-container .flex-inner-item:nth-child(2) {
    margin-left: 18px;
    flex: 0 1 auto;
}
