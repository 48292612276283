.menu {
    width: 220px;
    float: left;
    position:fixed;
    /*background:var(--primary-color);*/
    background-image: -webkit-linear-gradient(top, #03c1af 0, #028578 100%);
    background-image: -o-linear-gradient(top, #03c1af 0, #028578 100%);
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, #03c1af), to(#028578));
    background-image: linear-gradient(to bottom, #03c1af 0, #028578 100%);
    height: 100vh;
    min-width: 220px;
    outline: none;
    transition: all 0.3s ease-in-out;
}

.menu .expand-button {
    display: none;
}
.sidenav-container .content {
    padding-left: 230px;
    padding-right: 8px;
    padding-top: 8px;
    height: 100%;
}

.sidenav-container {
    height: 100%;
}

.custom-tooltip {
    display: none !important;
}

@media (min-width: 992px){
    .sidenav-container .container {
        width: calc(970px - 240px);
    }

}

@media (min-width: 768px){
    .sidenav-container .container {
        width: calc(100% - 70px);
    }
}

@media (min-width: 1200px){
    .sidenav-container .container {
        width: calc(1170px - 240px);
    }
}


.close-button {
    display: none;
}
.menu .patient-identification-box {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    background: rgba(0, 0, 0, 0.1);
    padding: 0.5em 0.5em;
    text-align: center;
    transition: all 0.3s ease-in-out;
}

.menu .patient-identification-box .photo {
    border-radius: 50%;
    overflow: hidden;
    border: 4px solid var(--primary-faded-color);
    box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.2);
    transition: all 0.3s ease-in-out;
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
    -webkit-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    align-self: auto;
    width: 68px;
    height: 68px;
    margin-right: 12px;
}

.menu .patient-identification-box .information {
    text-align: center;
    color: var(--text-white-color);
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
    -webkit-flex: 1 2 auto;
    -ms-flex: 1 2 auto;
    flex: 1 2 auto;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    align-self: center;
}

.menu .patient-identification-box .information .name{
    text-align: center;
    margin-top: 4px;
    font-size: 18px;
    color: var(--text-white-color);
}

.menu .patient-identification-box .information .gender.fa-venus, fa-mars {
    margin-right: 4px;
    background: white;
    padding: 1px 5px;
    border-radius: 5px;
    color: var(--primary-color)
}

.menu .patient-identification-box .information .gender.fa-mars {
    margin-right: 4px;
    background: white;
    padding: 1px 5px;
    border-radius: 5px;
    color: var(--primary-color)
}



.menu .patient-identification-box .information hr {
    margin: 10px;
}

.menu .patient-identification-box .information > div {
    color: var(--text-white-color);
}

.menu .patient-identification-box .information > div > .text-muted {
    color: var(--text-white-color);
    opacity: 0.8;
}
.menu ul {
    list-style: none;
    padding: 0.5em 0;
    margin: 0;
}
.menu .nav-item > a {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 8px 12px;
    transition: all 0.15s linear;
    cursor: pointer;
}

.menu .nav-item > a.active > * {
    color: var(--text-selected-color) !important;
}

.menu .nav-item > a.active {
    background: var(--selection-background-color);
    margin-left: 2px;
    border-radius: 20px 0 0 20px;
}

.menu .nav-item.inner-item > a {
    padding-left: 26px;
}

.menu .nav-item.inner-item > a.active > * {
    color: var(--text-selected-color);
}

.menu .nav-item.disabled > a {
    pointer-events: none;
}

.menu .nav-item.disabled > a > * {
    opacity: 0.4;
}

.menu .nav-item > a:focus {
    text-decoration: none !important;
}

.menu .nav-item.disabled:hover {
    background-color: inherit;
    cursor: auto;
}

.menu .nav-item.disabled:hover > a {
    cursor: not-allowed;
}
.menu .nav-item > a > span {
    flex: 0;
    margin-right: 0;
    min-width: 28px;
    transition: all 0.3s ease-in-out;
    float: left;
    color: var(--text-white-color);
}

.menu .nav-item > a > .text {
    flex: 1;
    color: var(--text-white-color);
}

.menu .nav-item > a > .text .badge {
    float: right;
    background: var(--text-white-color);
    color: var(--primary-color);
    padding-top: 2px;
    padding-bottom: 2px;
    font-size: small;
}

.menu .nav-item > a > .text > .caret-container {
    float: right;
}

.menu .nav-item > a > .text > .caret-container > span{
    color: var(--text-white-color);
}

.menu .nav-item.inner-item > a > .text .badge{
    padding-top: 2px;
    margin-top: 2px;
}

.menu .nav-item > a.active > .text .badge {
    background: var(--primary-color);
    color: var(--text-white-color);
}

.menu .nav-item:hover {
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 20px 0 0 20px;
}
.menu .nav-item:focus {
    outline: none;
}

.smartphone-menu-trigger {
    display: none;
}

@media screen and (max-width: 992px) and (min-width: 400px) {
    .sidenav-container {
        display: block;
    }

    .custom-tooltip {
        display: block !important;
    }
    .sidenav-container .content {
        padding-top: 16px;
        padding-left: 76px;
        padding-right: 6px;
        transition: all 0.3s ease-in-out;
    }
    .menu {
        min-width: 70px;
        position: absolute;
        width: 70px;
        transition: all 0.3s ease-in-out;
        z-index: 1000;
    }

    .menu .expand-button {
        text-align: center;
        color: var(--text-white-color);
        display: block;
        width: 100%;
        height: 40px;
        position: absolute;
        bottom: 5px;
        font-size: 2rem;
        transition: all 0.3s ease-in-out;
    }

    .menu .patient-identification-box {
        flex-direction: column;
        justify-content: center;
        justify-items: center;
        align-content: center;
    }

    .menu .patient-identification-box .photo {
        width: 50px;
        height: 50px;
        margin: 0;
    }

    .menu .patient-identification-box .information {
        display: none;
        opacity: 0;
    }

    .menu.open {
        z-index: 1001;
        width: 240px;
        transition: all 0.3s ease-in-out;
    }

    .menu.open .patient-identification-box {
        flex-direction: row;
        justify-content: center;
        justify-items: center;
        align-content: center;
    }

    .menu.open .patient-identification-box .information {
        display: block;
        opacity: 1;
    }

    .menu.open .patient-identification-box .photo {
        margin-right: 12px;
    }

    .menu .nav-item > a {
        transition: all 0.3s ease-in-out;
        display: block;
        height: 36px;
        margin-bottom: 10px;
        padding: 8px;
        position: relative;
    }

    .menu .nav-item.with-inner {
        color: red !important;
    }

    .menu.open .nav-item > a {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        align-content: center;
        padding: 8px 16px 8px 8px;
        transition: all 0.15s linear;
        cursor: pointer;
    }

    .menu.open .nav-item > a > span {
        flex: 0;
        margin-right: 8px;
        min-width: 28px;
        transition: all 0.3s ease-in-out;
        float: left;
        color: var(--text-white-color);
    }

    .menu.open .nav-item > a > .text {
        flex: 1;
        color: var(--text-white-color);
        display: block;
    }

    .menu .nav-item > a >span {
        width:100%;
        text-align: center;
        transition: all 0.3s ease-in-out;
        flex: 1;
        margin-right: 0;
        font-size: 2rem;
        opacity: 1;
    }

    .menu .nav-item.inner-item {
        margin-left: 0;
    }
    .menu .nav-item > a > .text {
        display: none;
        flex: 0;
    }
}
@media screen and (max-width: 400px) {
    .close-button {
        position: absolute;
        display: block;
        font-size: 3rem;
        right: 5px;
        color: whitesmoke;
        opacity: 0.7;
    }

    .sidenav-container {
        padding-top: 36px;
    }

    .custom-tooltip {
        display: none !important;
    }

    .sidenav-container {
        position: relative;
        display: block;
    }
    .sidenav-container .content {
        transition: all 0.3s ease-in-out;
        padding-left: 8px;
        padding-right: 8px;
    }
    .menu {
        flex: none;
        position: fixed;
        z-index: 101;
        overflow-y: scroll;
        width: 260px;
        top: 0;
        box-shadow: 0 0 0 100em rgba(0, 0, 0, 0);
        transform: translate3d(-260px, 0, 0);
        transition: all 0.3s ease-in-out;
    }
    .smartphone-menu-trigger {
        width: 40px;
        top: 48px;
        position: fixed;
        height: 40px;
        display: flex;
        z-index: 100;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        align-content: center;
    }
    .smartphone-menu-trigger span {
        flex: 1;
        text-align: center;
        font-size: 2.5rem;
        color: grey;
    }
    .menu .nav-item > a {
        padding: 1em 1em 1em 1em;
        font-size: 1.2em;
    }
    .menu.open {
        transform: translate3d(0, 0, 0);
        box-shadow: 0 0 0 100em rgba(0, 0, 0, 0.6);
    }
    .menu.open .smartphone-menu-trigger {
        pointer-events: none;
    }
}
