
/*Colors definition */
:root {
    --background-color: #f4f2ec;
    --primary-color: #74b09e;
    --primary-faded-color: #c6ded7;
    --secondary-color: #325291;

    --side-select-color: #74a4b0;

    --text-color: #333333;
    --text-white-color: #fbfbfb;
    --text-selected-color: #182628;

    --border-color: #b7b7b7;
    --border-color-light: #dddddd;
    --box-shadow-color: rgba(0,0,0,0.1);

    --attention-color: #E27D60;
    --selection-background-color: #f3f3f3;

    --badge-color: #7c9cca;

    --light-blue: #74c0d0;

    --light-blue-button-background: linear-gradient(to bottom,#b4ecff 0, #9be4ff 100%);
    --light-blue-button-color: #9be4ff;

    --dark-blue-button-background: linear-gradient(to bottom, #33ccff 0, #00bfff 100%);
    --dark-blue-button-color: #00bfff;
}
/*End of Colors definition */



html {
    height: 100%;
}
body {
    background-color: var(--background-color);
    height: 100%;
    margin: 0;
    padding: 50px 0 0;
    font-family: 'Open Sans', sans-serif !important;
}


#root {
    height: calc(100%);
}

ul {
    -moz-padding-start: 0;
    -webkit-padding-start: 0;
}

.border {
    border: 1px solid red;
}

.testing * {
    border: 1px solid;
}

.icon-margin-right {
    margin-right: 8px;
}

.no-margin-hr {
    margin-top: 0;
    margin-bottom: 0;
}

.btn {
    border-radius: 20px;
    padding-left: 14px;
    padding-right: 14px;
}

.btn-md {
    padding: 12px;
    font-size: larger;
}

button:focus {
    outline: none;
}

.full-height {
    height: calc(100%);
}

.button-fa {
    line-height: 1.42857143 !important;
}

*:focus {
    outline: none !important;
    color: var(--text-color) !important;
}

.no-side-padding-for-container {
    padding-left: 0;
    padding-right: 0;
}

.no-side-padding {
    padding-left: 0;
    padding-right: 0;
}

.small-side-padding {
    padding-left: 2px;
    padding-right: 2px;
}

.btn-primary:focus {
    color: var(--text-white-color) !important;
}

.btn-success:focus {
    color: var(--text-white-color) !important;
}


.small-margin {
    margin: 2px
}


.bordered {
    border: 1px solid red;
}

.margin-bottom-form-input {
    margin-bottom: 15px;
}

.faded {
    opacity: 0.5;
}

.border-all > * {
    border: 1px solid red;
}

.loading-window {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.form-control.input-sm {
    line-height: unset;
}

.loading-window > span {
    color: var(--secondary-color);
}

.loading {
    margin: 20px;
    text-align: center;
}

.loading span {
    color: var(--secondary-color);
}


.hover-effect:hover {
    box-shadow: 2px 2px 5px 0 var(--box-shadow-color);
}
