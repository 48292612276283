.patient-history-container .receipt-viewer {
    padding-left: 8px;
}

.select-receipt {
    border: 1px solid var(--border-color);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    justify-content: center;
}


.history-panel .panel-body {
    padding-left: 0;
    padding-right: 0;
}

.patient-select {
    width: auto;
}
