.no-favorites {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    justify-content: center;
}

.favorite-footer {
    padding-left: 16px;
    margin-bottom: 4px;
    padding-bottom: 4px;
}

.favorite-box .static-medicine {
    margin-bottom: 0;
}

.favorite-box ul {
    margin-bottom: 0;
}

.u-position-absolute {
    position: absolute;
}

.u-position-relative {
    position: relative;
}

@keyframes fadein {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes move-down {
    0% {
        transform: translateY(0);
    }
    10% {
        transform: translateY(0);
    }
    20% {
        transform: translateY(5px);
    }
    30% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(0);
    }
}

@keyframes move-up {
    0% {
        transform: translateY(0);
    }
    10% {
        transform: translateY(0);
    }
    20% {
        transform: translateY(-5px);
    }
    30% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(0);
    }
}
