.qr-container {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    padding-bottom: 100px;
}

.qr-container .jumbotron {
    border-radius: 20px;
    padding: 5%;
    order: 0;
    flex: 1 1 auto;
}

.qr-container .qr-code-box {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: center;
    align-items: center;
    padding: 10px;
    background: white;
    border-radius: 20px;
    margin-bottom: 50px;
    margin-top: 20px;
}

.qr-container .qr-code-box img {
    width: 250px;
    height: 250px;
}

.flex-container {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-content: center;
    align-items: center;
}

.qr-container .jumbotron {
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
    -webkit-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    align-self: auto;
}

.qr-container .qr-code-box {
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
    -webkit-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    align-self: auto;
}

.qr-container .jumbotron p:last-child{
    margin-bottom: 0;
}

@media screen and (max-width: 768px) {
    .qr-container .jumbotron {
        padding: 20px;
    }
    .qr-container .jumbotron h2 {
        font-size: 22px;
    }
    .qr-container .jumbotron br {
        display: none;
    }
    .qr-container .jumbotron p{
        font-size: 18px;
        padding-top: 10%;
        padding-bottom: 0;
        flex-direction: column;
    }
    .qr-container .qr-code-box {
        margin-left: 0;
    }
}
