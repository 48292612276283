.secondary {
    color: grey;
    font-size: 95%;
}

.secondary.bold {
    color: unset;
    font-weight: 900;
}

.inline-spacing div{
    margin: 0;
}

.btn-middle {
    border-radius: 0;
}

.btn-left {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.btn-right {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.text-input-static {
    padding-left: 6px;
    padding-right: 6px;
    display: inline-block;
    font-size: 12px;
    line-height: 1.5;
}

.dropdown-menu>li{
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: 400;
    line-height: 1.42857143;
    color: #333;
    white-space: nowrap;
}

.dropdown-menu>li:hover{
    background: #e8e8e8 repeat-x;
    background-image: -webkit-linear-gradient(top,#f5f5f5 0,#e8e8e8 100%);
    background-image: -o-linear-gradient(top,#f5f5f5 0,#e8e8e8 100%);
    background-image: -webkit-gradient(linear,left top,left bottom,from(#f5f5f5),to(#e8e8e8));
    background-image: linear-gradient(to bottom,#f5f5f5 0,#e8e8e8 100%);
    color: #262626;
    text-decoration: none;
    background-color: #f5f5f5;
    cursor: pointer;
}

/*.medication-info-line > .first-line {*/
/*    padding-top: 12px;*/
/*    line-height: 16px;*/
/*}*/

/*.medication-info-line .secondary {*/

/*}*/

/*.medication-info-line span{*/
/*    padding: 0 8px;*/
/*}*/

/*.medication-info-line span:not(:first-child){*/
/*    border-left:1px solid darkslategrey;*/
/*}*/

/*.medication .pull-right {*/
/*    text-align: right;*/
/*}*/

.medication {
    border: 1px solid var(--border-color);
    background: white;
    border-radius: 20px;
    margin-bottom: 4px;
    margin-left: 0;
    margin-right: 0;
}

.medication:hover {
    box-shadow: 2px 2px 5px 0 var(--box-shadow-color);
}

.medication.selected {
    box-shadow: 0 0 5px 0 rgba(0,0,0,0.6);
}

.medication .form-horizontal {
    line-height: normal;
}

.selectable:hover {
    background-color: lightblue;
    cursor: pointer;
}

.plus-minus-container {
    padding-left: 6px;
    padding-right: 6px;
}

.add-button{
    background: lightblue;
    line-height: 126px;
    min-width: 50px;
    height: 126px;
    text-align: center !important;
    font-weight: bolder;
    color: white;
}

.renewable-description {
    margin-right: 2px;
}

.renewable-description .btn {
    padding-left: 10px;
    padding-right: 10px;
}

.minus-button {
    background: var(--light-blue-button-background);
    color: white;
}

.minus-button[disabled]{
    background: var(--light-blue-button-color);
    opacity: 0.5;
}
.plus-button {
    background: var(--dark-blue-button-background);
    color: white;
}

.plus-button[disabled]{
    background: var(--dark-blue-button-color);
    opacity: 0.5;
}
.plus-button:hover, .minus-button:hover {
    color: gray;
}

.plus-button:focus, .minus-button:focus {
    outline: none;
}

.plus-button:active {
    background: #33ccff;
    box-shadow: none;
}

.minus-button:active {
    box-shadow: none;
    background: #b4ecff;
}

.medication-info-line span.justify{
    border-left:2px solid #ff4d4d !important;
    color: #ff4d4d;
}

.top-margin {
    margin-top: 8px;
}

fieldset > *:last-child{
    margin-bottom: 0;
}




@media (max-width: 767px) {
    .btn-xs-block {
        display: block;
        width: 100%;
    }
    input[type="submit"].btn-xs-block,
    input[type="reset"].btn-xs-block,
    input[type="button"].btn-xs-block {
        width: 100%;
    }
    .btn-block + .btn-xs-block,
    .btn-xs-block + .btn-block,
    .btn-xs-block + .btn-xs-block {
        margin-top: 0.5rem;
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    .btn-sm-block {
        display: block;
        width: 100%;
    }
    input[type="submit"].btn-sm-block,
    input[type="reset"].btn-sm-block,
    input[type="button"].btn-sm-block {
        width: 100%;
    }
    .btn-block + .btn-sm-block,
    .btn-sm-block + .btn-block,
    .btn-sm-block + .btn-sm-block {
        margin-top: 0.5rem;
    }
}
@media (min-width: 992px) and (max-width: 1199px) {
    .btn-md-block {
        display: block;
        width: 100%;
    }
    input[type="submit"].btn-md-block,
    input[type="reset"].btn-md-block,
    input[type="button"].btn-md-block {
        width: 100%;
    }
    .btn-block + .btn-md-block,
    .btn-md-block + .btn-block,
    .btn-md-block + .btn-md-block {
        margin-top: 0.5rem;
    }
}
@media (min-width: 1200px) {
    .btn-lg-block {
        display: block;
        width: 100%;
    }
    input[type="submit"].btn-lg-block,
    input[type="reset"].btn-lg-block,
    input[type="button"].btn-lg-block {
        width: 100%;
    }
    .btn-block + .btn-lg-block,
    .btn-lg-block + .btn-block,
    .btn-lg-block + .btn-lg-block {
        margin-top: 0.5rem;
    }
}



.first-line {
    padding: 6px 6px 0 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-items: center;
    justify-content: flex-start;
    justify-self: center;
}

.first-line .name {
    order: 0;
    flex: 1 1 auto;
    align-self: auto;
}

.first-line .buttons {
    order: 0;
    flex: 0 0 auto;
    align-self: auto;
}

.additional-content {
    padding: 0 6px 0 6px;
    margin-bottom: 6px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-items: center;
    justify-content: space-between;
    justify-self: center;
}

.additional-content .information {
    order: 0;
    flex: 1 1 auto;
    align-self: auto;
}

.additional-content .information span{
    display: inline;
    padding: 0 6px;
}

.additional-content .information span:not(:first-child){
    border-left:1px solid darkslategrey;
}

.additional-content .buttons {
    order: 0;
    flex: 0 0 auto;
    align-self: auto;
}


@media only screen and (max-width: 600px) {
    .additional-content .information span {
        border-left:1px solid darkslategrey;
    }
    .additional-content .information span{
        display: block;
    }
}
