.two-line-group {
    height: 48px;
}

.two-line-group .input-group-addon > * {
    margin-top: 500px
}

.two-line-group .form-control {
    height: 48px;
}

.two-line-group .title {
    font-size: 100%;
}

.two-line-group .subtext {
    font-size: 80%;
    color: grey;
}

.pathology-addon {
    background: #cef2ff;
}
