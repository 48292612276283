.custom-icon{
    width: 18px;
    height: 18px;
    margin-bottom: 3px;
}

.custom-icon.small {
    width: 13px;
    height: 13px;
}

.custom-icon.center-with-text {
    margin-bottom: 3px;
}
.badge .custom-icon {
    margin-bottom: 0;
}

/*TODO change this to actually be a color for icon*/
.custom-icon {
    filter: invert(1);
}

.custom-icon.white {
    filter: invert(0);
}
