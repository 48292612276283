.timeline {
    position: relative;
    overflow: auto;
}
.timeline:before {
    content: '';
    position: absolute;
    height: 100%;
    width: 5px;
    background: var(--secondary-color);
    left: 0;
}
.timeline h3 {
    border-radius: 100px;
    background: var(--secondary-color);
    width: fit-content;
    color:white;
    margin: 0 auto 1em;
    padding: 4px 12px;
    text-align: center;
    position: relative;
    clear: both;
    font-size: 18px;
}

.timeline h4{
    font-size: 16px;
}

.timeline h4 span {
    font-weight:bold;
}
.timeline ul {
    list-style: none;
    padding: 0 0 0 20px;
    z-index: 1;
}
.timeline li {
    border-radius: 0 15px 15px 15px;
    padding: 8px;
    margin-bottom: 8px;
    position: relative;
}
.timeline li:before {
    content: '';
    width: 0;
    height: 0;
    border-top: 1em solid #efefef;
    border-left: 1em solid transparent;
    position: absolute;
    left: -1em;
    top: 0;
}
.timeline h4 {
    margin-top: 0;
}
.timeline time {
    font-size:14px;
    font-weight: 300;
}

@media screen and (min-width: 40em) {
    .timeline:before {
        left: 50%;
    }
    .timeline ul {
        padding-left: 0;
        max-width: 100%;
        margin: 0 auto;
    }
    .timeline li {
        width: 45%;
    }
    .timeline li:nth-child(even) {
        float: right;
        border-top-left-radius: 0;
        margin-top: 25px;
    }
    .timeline li:nth-child(odd) {
        border-top-left-radius: 15px;
        float: left;
    }
    .timeline li:nth-child(odd):before {
        border-top: 1em solid #efefef;
        border-right: 1em solid transparent;
        right: -1em;
        left: auto;
    }
    .timeline li:nth-of-type(2n+1) {
        clear: both;
    }
}

.timeline .red {
    color: #d82929;
    background-color: #f0abab;
    border-color: #ec9595;
}

.timeline .red:before {
    content: '';
    width: 0;
    height: 0;
    border-top: 1em solid #f2dede;
    border-left: 1em solid transparent;
    position: absolute;
    left: -1em;
    top: 0;
}

.timeline .red:before {
    content: '';
    width: 0;
    height: 0;
    border-top: 1em solid #f2dede !important;
    border-left: 1em solid transparent;
    position: absolute;
    left: -1em;
    top: 0;
}

.timeline .red:nth-child(odd):before{
    border-top: 1em solid #f2dede !important;
    border-right: 1em solid transparent;
    right: -1em;
    left: auto;
}

.timeline .green {
    border-color: #d6e9c6;
    background-color: #dff0d8;
    color: #609238;
}

.timeline .green:before {
    content: '';
    width: 0;
    height: 0;
    border-top: 1em solid #dff0d8;
    border-left: 1em solid transparent;
    position: absolute;
    left: -1em;
    top: 0;
}


.timeline .green:before {
    content: '';
    width: 0;
    height: 0;
    border-top: 1em solid #dff0d8 !important;
    border-left: 1em solid transparent;
    position: absolute;
    left: -1em;
    top: 0;
}

.timeline .green:nth-child(odd):before{
    border-top: 1em solid #dff0d8 !important;
    border-right: 1em solid transparent;
    right: -1em;
    left: auto;
}

.timeline .purple {
    color: #6b48c8;
    background-color: #bbabe6;
    border-color: #ab97e0;
}

.timeline .purple:before {
    content: '';
    width: 0;
    height: 0;
    border-top: 1em solid #bbabe6;
    border-left: 1em solid transparent;
    position: absolute;
    left: -1em;
    top: 0;
}


.timeline .purple:before {
    content: '';
    width: 0;
    height: 0;
    border-top: 1em solid #bbabe6 !important;
    border-left: 1em solid transparent;
    position: absolute;
    left: -1em;
    top: 0;
}

.timeline .purple:nth-child(odd):before{
    border-top: 1em solid #bbabe6 !important;
    border-right: 1em solid transparent;
    right: -1em;
    left: auto;
}

.timeline .orange {
    color: #ffa302;
    background-color: #ffe4b4;
    border-color: #ffdb9a;
}

.timeline .orange:before {
    content: '';
    width: 0;
    height: 0;
    border-top: 1em solid #ffe4b4;
    border-left: 1em solid transparent;
    position: absolute;
    left: -1em;
    top: 0;
}


.timeline .orange:before {
    content: '';
    width: 0;
    height: 0;
    border-top: 1em solid #ffe4b4 !important;
    border-left: 1em solid transparent;
    position: absolute;
    left: -1em;
    top: 0;
}

.timeline .orange:nth-child(odd):before{
    border-top: 1em solid #ffe4b4 !important;
    border-right: 1em solid transparent;
    right: -1em;
    left: auto;
}


.timeline .yellow {
    color: #b2a400;
    background-color: #fff9af;
    border-color: #fff795;
}

.timeline .yellow:before {
    content: '';
    width: 0;
    height: 0;
    border-top: 1em solid #fff795;
    border-left: 1em solid transparent;
    position: absolute;
    left: -1em;
    top: 0;
}

.timeline .yellow:before {
    content: '';
    width: 0;
    height: 0;
    border-top: 1em solid #fff795 !important;
    border-left: 1em solid transparent;
    position: absolute;
    left: -1em;
    top: 0;
}

.timeline .yellow:nth-child(odd):before{
    border-top: 1em solid #fff795 !important;
    border-right: 1em solid transparent;
    right: -1em;
    left: auto;
}
