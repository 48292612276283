.buttons-container > * {
    margin-right: 4px;
    margin-bottom: 8px;
}

.buttons-container {
    text-align: right;
}

.dropdown-menu .dropdrown-group-title {
    text-transform: uppercase;
    color: #999999;
    font-size: 11px;
    font-weight: bold;
}

.dropdown-menu {
    border-radius: 20px;
    padding-top: 0;
    padding-bottom: 0;
}

.dropdown-menu > li:first-child {
    padding-top: 5px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}


.dropdown-menu > li:last-child {
    padding-bottom: 5px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}

.dropdown-menu > li.divider {
    padding: 0;
}

.dropdown-menu >li.dropdrown-group-title:hover {
    background: white !important;
    color: #999999;
    cursor: default !important;
}
