
.visible-xxs {
    display: none;
}

.allergies-insertion .modal-body {
    padding: 0;
}

.allergies-insertion .modal-content .medication-insertion {
    background-color: #EFBBA2;
}

.allergies-insertion .modal-content .medication-insertion .modal-body {
    padding: 15px;
}

.allergies-insertion .modal-content .other-insertion .modal-body {
    padding: 15px;
}
.allergies-insertion .modal-content .other-insertion {
    background-color: #efe2a2;
}

.allergies-insertion .modal-content .no-bg {
    padding: 15px;
    background-color: white;
}

.allergies-insertion .modal-header {
    border-bottom-color: var(--border-color-light);
}

@media (max-width: 400px) {
    .hidden-xxs {
        display: none;
    }

    .visible-xxs {
        display: block;
    }
}
