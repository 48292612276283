.navbar {
    margin-bottom:0;
}

.navbar-brand {
    padding-top: 0;
}

.navbar-fixed-top {
    z-index: 999;
}

.navbar-brand img {
    padding-top: 6px;
    padding-bottom: 6px;
    width: 50px;
    height: 50px;
}

.navbar .active {
    color: #66acf6 !important;

}

.navbar-text {
    padding-left: 4px;
    padding-right: 4px;
}


.navbar-left {
    margin-left: 10px
}

.navbar-left > li {
    height: 50px;
}

.navbar-nav>li>a {
    height: 50px;
    padding-top: 15px;
    padding-bottom: 10px;
}

.navbar-right > li > a {
    padding-top: 10px;
}

.navbar-nav>li>a>p {
    margin-top: 5px;
    margin-bottom: 5px;
}


@media (max-width: 767px) {

    .navbar-left {
        margin-left: 0
    }
    .navbar-nav {
        margin: auto;
    }

    .navbar-form {
        margin-right: 0
    }

    .navbar .navbar-form .input-group > input {
        width: 100%;
    }

    .double-line-navigation {
        padding-left: 15px;
    }

    .double-line-navigation > .secondary-info {
        text-align: left;
    }
}
