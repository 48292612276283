.patient-history-container .receipt-viewer {
    padding-left: 8px;
}

.select-receipt {
    border: 1px solid var(--border-color);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    justify-content: center;
}


.history-panel .panel-body {
    padding-left: 0;
    padding-right: 0;
}


/*.patient-history-container .receipt-viewer {*/
/*    display: flex;*/
/*    flex-wrap: nowrap;*/
/*    flex-direction: row;*/
/*    justify-content: flex-start;*/
/*    align-content: center;*/
/*    align-items: stretch;*/
/*}*/

/*.patient-history-container .receipt-viewer .history-panel.full {*/
/*    flex: 2 0 auto;*/
/*    margin-right: 6px;*/
/*    transition: 200ms;*/
/*    align-self: auto;*/
/*}*/


/*.patient-history-container .receipt-viewer .history-panel.fitted {*/
/*    transition: 200ms;*/
/*    max-width: 100%;*/
/*    flex: 0 1 auto;*/
/*    align-self: auto;*/
/*}*/

/*.patient-history-container .receipt-viewer .receipt {*/
/*    flex: 9 0 auto;*/
/*    align-self: auto;*/
/*    margin-left: 6px;*/
/*}*/
