.header {
    line-height: 42px;
}

.medicine-list-line {
    padding-bottom: 0;
}

.medicine-list-line .static-medicine {
    margin-bottom: 0;
}

@media screen and (max-width: 768px) {
    .footer.pull-right {
        margin-top: 10px;
        float:none !important
    }
}
