/*Tabs Buttons Styling*/
.nav-tabs>li.active>button, .nav-tabs>li.active>button:focus, .nav-tabs>li.active>button:hover {
    color: #555;
    cursor: default;
    background-color: #fff;
    border: 1px solid #ddd;
    border-bottom-color: transparent;
}

.nav-tabs>li>button {
    margin-right: 2px;
    line-height: 1.42857143;
    border: 1px solid transparent;
    border-radius: 20px 20px 0 0;
}

.nav>li>button {
    position: relative;
    display: block;
    padding: 10px 15px;
}

.nav-tabs>li>button {
    background-color: transparent;
    color: #337ab7;
    margin-right: 2px;
    line-height: 1.42857143;
    border: 1px solid transparent;
    border-radius: 20px 20px 0 0;
}

.nav>li>button {
    position: relative;
    display: block;
    padding: 10px 15px;
}

.nav-tabs>li>button:hover {
    border-color: #eee #eee #ddd;
}

.nav>li>button:focus, .nav>li>button:hover {
    outline: none;
    text-decoration: none;
    background-color: #eee;
}

/*NavBar Buttons Styling*/

/*NavBar Pills Styling */

.nav-pills>li.active>button, .nav-pills>li.active>button:focus, .nav-pills>li.active>button:hover {
    color: #fff !important;
    background-color: #337ab7;
}

.nav-pills>li>button {
    border-radius: 20px;
}

.nav>li>button {
    position: relative;
    display: block;
    padding: 10px 15px;
}

.nav-pills>li>button {
    border-radius: 20px;
    border: none;
}
.nav>li>button {
    position: relative;
    display: block;
    padding: 10px 15px;
}


/*NavBar Pills Styling */


/*Navbar Justified Styling*/

.nav-tabs.nav-justified {
    width: 100%;
    border-bottom: 0;
}
.nav-pills.nav-justified {
    width: 100%;
}
.nav-justified {
    width: 100%;
}

.nav-tabs.nav-justified>li {
    float: none;
}
.nav-pills.nav-justified>li {
    float: none;
}
.nav-justified > li > button {
    width: 100%;
}

@media (min-width: 768px) {
    .nav-tabs.nav-justified>.active>button, .nav-tabs.nav-justified>.active>button:focus, .nav-tabs.nav-justified>.active>button:hover {
        border-bottom-color: #fff;
    }

    .nav-tabs.nav-justified>li>button {
        margin-bottom: 0;
    }

    .nav-tabs.nav-justified>li>button {
        border-bottom: 1px solid #ddd;
        border-radius: 20px 20px 0 0;
    }
    .nav-pills.nav-justified>li>button {
        border-radius: 20px;
    }

    .nav-justified>li>button {
        margin-bottom: 0;
    }

    .nav-tabs.nav-justified>li {
        display: table-cell;
        width: 1%;
    }
    .nav-pills.nav-justified>li {
        display: table-cell;
        width: 1%;
    }

    .nav-justified>li>button {
        margin-bottom: 5px;
        text-align: center;
    }
}

.nav-tabs.nav-justified>.active>button, .nav-tabs.nav-justified>.active>button:focus, .nav-tabs.nav-justified>.active>button:hover {
    border: 1px solid #ddd;
    border-bottom: #fff;
}

.nav-tabs.nav-justified>li>button {
    margin-right: 0;
    border-radius: 20px;
}
.nav-pills.nav-justified>li>button {
    margin-right: 0;
    border-radius: 20px;
}

.nav-tabs.nav-justified>li>button {
    border-bottom: 1px solid #ddd;
    border-radius: 20px 20px 0 0;
}

.nav-tabs.nav-justified>li>button {
    margin-bottom: 5px;
    text-align: center;
}

.nav-pills.nav-justified>li>button {
    margin-bottom: 5px;
    text-align: center;
}

/*Navbar Justified Styling*/
