.confirmation-container > div {
    padding: 8px;
}

.receipt-signer-modal .modal-body {
    background:var(--background-color);
}



/*.receipt-signer-window .accordion > *:first-child {*/
/*    border-top-left-radius: 20px;*/
/*    border-top-right-radius: 20px;*/
/*}*/


/*.receipt-signer-window .accordion > *:first-child > .accordion__heading .accordion__button {*/
/*    border-top-left-radius: 20px;*/
/*    border-top-right-radius: 20px;*/
/*}*/

/*.receipt-signer-window .accordion > *:first-child > .accordion__heading {*/
/*    border-top-left-radius: 20px;*/
/*    border-top-right-radius: 20px;*/
/*}*/

/*.receipt-signer-window .accordion .accordion__panel > div {*/
/*    background-color: red;*/
/*}*/


/*.receipt-signer-window .accordion__body {*/
/*    border: 1px solid whitesmoke;*/
/*    padding: 8px 16px;*/
/*}*/

/*.receipt-signer-window .accordion > .accordion__panel:nth-child(even) {*/
/*    background: red;*/
/*}*/

.sign-box {
    border: 1px solid #cccccc;
    border-radius: 20px;
    display: flex;
    padding-bottom: 10px;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.sign-box > div {

    width: 300px;
    /*flex: 1 0 auto;*/
}


.item-line {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-content: center;
    align-items: center;
}

.item-line > div:first-child {
    order: 0;
    flex: 1 0 auto;
    align-self: auto;
}

.item-line > div:last-child {
    order: 0;
    flex: 0 0 auto;
    align-self: auto;
}

.medicine-list {
    box-shadow: none;
}

.medicine-list > .list-group-item {
    border-radius: 20px;
}

.medicine-list > .list-group-item:nth-child(odd) {
    background: whitesmoke;
}


.receipt-line-title {
    display: flex;
    flex-direction: row;
}

.receipt-line-title > *:nth-child(1) {
    flex: 1 0 auto;
    font-weight: bold;
}

.receipt-line-title > *:nth-child(2) {
    position: absolute;
    right: 24px;
}
