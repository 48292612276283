.basket {
    border: 1px solid #6fa9a9;
    border-radius: 5px;
}

.basket-list ul{
    margin-bottom: 0;
}

.badge {
    font-size: 16px;
    padding: 4px 8px;
    color: white;
    background: linear-gradient(to bottom, var(--badge-color) 0, var(--badge-color) 100%);
}


.basket-panel > .panel > .panel-content > .panel-body {
    padding: 8px 6px;
}

/*Notification Bubble*/

.notification-bubble {
    position: absolute;
    top: -4px;
    right: -4px;
    padding: 6px;
    border-radius: 50%;
    background: #db282e;
    color: white;
}




/*Notification Bubble*/
