/*.label-margin > input{*/
    /*margin-right: 4px;*/
/*}*/

/*.label-align-right{*/
    /*text-align: right;*/
/*}*/

/*.list-close-icon {*/
    /*margin-top: 2px;*/
    /*color: red;*/
/*}*/
/*.list-add-icon {*/
    /*margin-top: 2px;*/
    /*font-weight: bolder;*/
    /*color: #1e87f0;*/
/*}*/

/*.icon-button{*/
    /*cursor: pointer;*/
/*}*/

/*.selection-list {*/
    /*padding-top: 7px;*/
    /*padding-bottom: 4px;*/
    /*margin-bottom: 2px;*/
    /*margin-top:2px;*/
/*}*/

/*.selection-list .placeholder{*/
    /*color: #dddddd;*/
/*}*/

/*@media only screen and (max-width: 959px) {*/
    /*.label-align-right{*/
        /*text-align: left;*/
    /*}*/
/*}*/


/*.autocomplete-dropdown-container{*/
    /*position: absolute;*/
    /*z-index: 1020;*/
    /*box-sizing: border-box;*/
    /*min-width: 200px;*/
    /*padding: 25px;*/
    /*background: #fff;*/
    /*color: #666;*/
    /*box-shadow: 0 5px 12px rgba(0,0,0,0.15);*/
/*}*/

/*.autocomplete-list-suggestion:hover{*/
    /*cursor: pointer;*/
    /*transition: color 0.2s ease;*/
    /*color: #00198d;*/
/*}*/

/*.autocomplete-list {*/
    /*margin-bottom: 0;*/
/*}*/

/*.uk-textarea {*/
    /*resize: vertical;*/
    /*border-radius: 10px;*/
/*}*/

/*.uk-select, .uk-button, .uk-input {*/
    /*border-radius: 10px;*/
/*}*/

.form-control {
    border-radius: 20px;
}

/*TODO Put caret back in place... those borders, needed to get a hack to round select, but caret dissapeared.*/

select.form-control {
    -webkit-appearance: none;
    -moz-appearance: none;
    background-position: right 50%;
    background-repeat: no-repeat;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAMCAYAAABSgIzaAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NDZFNDEwNjlGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NDZFNDEwNkFGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo0NkU0MTA2N0Y3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo0NkU0MTA2OEY3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuGsgwQAAAA5SURBVHjaYvz//z8DOYCJgUxAf42MQIzTk0D/M+KzkRGPoQSdykiKJrBGpOhgJFYTWNEIiEeAAAMAzNENEOH+do8AAAAASUVORK5CYII=);
}

select::-ms-expand {
    width:12px;
    border:none;
    background:#fff;
}

input[type="date"].form-control {
    line-height: 20px;
}

input[type='date'], input[type='time'] {
    -webkit-appearance: none;
}

input[type="checkbox"] {
    margin-right: 4px;
}

.radio-inline {
    margin-left: 10px;
}

.grouped-item {
    margin-bottom: 0;
}

.grouped-item .form-control {
    border-radius: 0;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.disabled-input {
    height: 24px;
    border-bottom: 1px solid var(--border-color);
    padding-top: 5px;
    margin-bottom: 0;
    text-align: left;
    font-size: 14px;
}

.disabled-input .text-muted {
    font-size: 14px;
    opacity: 0.5;
}

.disabled-input.warning {
    border-color: var(--attention-color);
}
.disabled-input.warning:after {
    color: var(--attention-color);
    min-height: 22px;
    margin-top: -5px;
    margin-right: 8px;
    float: right;
    font-family: "Font Awesome 5 Free"; font-weight: 900; content: "\f071";
}

.radio-inline .disabled-input {
    padding-top: 0;
    margin-left: -30px;
}

.rounded-left {
    border-top-left-radius: 20px !important;
    border-bottom-left-radius: 20px !important;
}
