.conversation-container {
    overflow-y: scroll;
    height: calc(100% - 56px);
}

.send-container {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
}

.message {
    margin: 10px auto;
}

.message > div > .left-side, .right-side {
    border-radius: 16px;
    padding: 4px 12px;
}

.left-side {
    background: lightgray;
}

.right-side {
    color: white;
    background: #5e94ca;
}

.small-text {
    font-size: x-small;
}

.small-text-left {
    margin-left: 4px;
}


.small-text-right {
    margin-right: 4px;
}

.send-message-box > .textarea {
    resize: vertical;
    width: calc(100% - 68px) !important;
    margin-right: 2px;
}

.send-message-box button {
    height: 54px
}
