.text-right {
    display:inline-block;
    float: right;
    margin-right: 10px;
}


.row{
    line-height: 42px;
}

.panel-heading {
    min-height: 42px;
    padding-top: 0;
    padding-bottom: 0;
}

.panel-info {
    border-color: #d9edf7;
}
.panel-info>.panel-heading {
    background-image: linear-gradient(to bottom, #eef7fb 0, #d9edf7 100%);
}

.panel-footer > * {
    margin-right: 16px;
}

.panel-footer > .pull-right {
    margin-right: 0;
}

.panel.rounded-corners .panel-heading {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    padding: auto 10px;
}

.panel.rounded-corners .panel-footer {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    padding: auto 10px;
}

.rounded-corners {
    border-radius: 20px;
}

.panel-warning-attention > .panel-heading {
    background-image: -webkit-linear-gradient(top, #eeb2a1 0%, #eaa08b 100%);
    background-image: -o-linear-gradient(top, #eeb2a1 0%, #eaa08b 100%);
    background-image: -webkit-gradient(linear, left top, left bottom, from(#eeb2a1), to(#eaa08b));
    background-image: linear-gradient(to bottom, #eeb2a1 0%, #eaa08b 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffeeb2a1', endColorstr='#ffeaa08b', GradientType=0);
    background-repeat: repeat-x;
}

.panel-warning-attention {
    border-color: #ea908b;
}
.panel-warning-attention > .panel-heading {
    color: #000000;
    background-color: #eeb2a1;
    border-color: #ea908b;
}
.panel-warning-attention > .panel-heading + .panel-collapse > .panel-body {
    border-top-color: #ea908b;
}
.panel-warning-attention > .panel-heading .badge {
    color: #eeb2a1;
    background-color: #000000;
}
.panel-warning-attention > .panel-footer + .panel-collapse > .panel-body {
    border-bottom-color: #ea908b;
}


.panel-warning > .panel-heading {
    background-image: -webkit-linear-gradient(top, #f5e79e 0%, #f3e086 100%);
    background-image: -o-linear-gradient(top, #f5e79e 0%, #f3e086 100%);
    background-image: -webkit-gradient(linear, left top, left bottom, from(#f5e79e), to(#f3e086));
    background-image: linear-gradient(to bottom, #f5e79e 0%, #f3e086 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff5e79e', endColorstr='#fff3e086', GradientType=0);
    background-repeat: repeat-x;
}

.panel-warning {
    border-color: #f3d086;
}
.panel-warning > .panel-heading {
    color: #544324;
    background-color: #f5e79e;
    border-color: #f3d086;
}
.panel-warning > .panel-heading + .panel-collapse > .panel-body {
    border-top-color: #f3d086;
}
.panel-warning > .panel-heading .badge {
    color: #f5e79e;
    background-color: #8a6d3b;
}
.panel-warning > .panel-footer + .panel-collapse > .panel-body {
    border-bottom-color: #f3d086;
}
