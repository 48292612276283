.margin-bottom {
    margin-bottom: 8px;
}

.messages-window-container {
    height: calc(100% - 10px);
}

.messages {
    height: 100%;
    margin-bottom: 0;
}

.messages .panel-body {
    padding-left: 0;
    padding-right: 0;
}

.messages .panel-body > :first-child {
    padding-left: 8px;
    padding-right: 8px;
}

.messages .message-item {
    border-left: none;
    border-right: none;
    border-radius: 0;
}

.message-item:hover {
    cursor: pointer;
    background: whitesmoke;
}


.conversation-line li {
    list-style: none;
}
